import React, { CSSProperties, useEffect } from 'react';

import DatePicker from '@components/inputs/DateInput';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { Country } from '@utils/constants/localisation';
import { dateToISOFormat } from '@utils/misc/functions';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';


/**
 * View intended to be integrated into the RAV onboarding form.
 *
 * All inputs are stored into the corresponding section of the RAV context.
 *
 * All inputs default values are taken from the context. All information
 * related to the driver should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has
 * to manually write all of them.
 */
export default function DriverLicence({ breadcrumbObject }: BreadcrumbProps) {
    const currentDate: Date = new Date();
    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };
    const provinceByCountry: { [key in Country]: string[] } = {
        'GB': ['LDN'],
        'AU': ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'VIC', 'TAS', 'WA']
    };

    const { formState: { driverDetails }, updateFormState } = useUserJourneyContext();

    // Minimum age can be 23 years old. Applicable to AU & UK.
    const minimumAge: string = dateToISOFormat(new Date(
        currentDate.getFullYear() - 23,
        currentDate.getMonth(),
        currentDate.getDate()
    ));
    // The maximum age a driver can be is 100 years old. This applies to both AU & UK
    const maximumAge: string = dateToISOFormat(new Date(
        currentDate.getFullYear() - 100,
        currentDate.getMonth(),
        currentDate.getDate()
    ));
    // The maximum length of a driver's licence before it expires is 10 years, 11 is
    // used for safety. Applies to both AU & UK.
    const maxDriverLicence: string = dateToISOFormat(new Date(
        currentDate.getFullYear() + 11,
        currentDate.getMonth(),
        currentDate.getDate()
    ));

    const onDriverLicenceChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        updateFormState(UserFormStates.driverDetails, { driversLicenceNumber: e.target.value });
    const onStateChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        updateFormState(UserFormStates.driverDetails, { province: e.target.value });
    const onExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        updateFormState(UserFormStates.driverDetails, { driversLicenceExpiry: e.target.value })
    const onDateOfBirthChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        updateFormState(UserFormStates.driverDetails, { dateOfBirth: e.target.value });

    const onPCOLicenseChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        updateFormState(UserFormStates.driverDetails, { pcoLicenseNumber: e.target.value });
    const onPCOExpiryDateChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        updateFormState(UserFormStates.driverDetails, { pcoLicenceExpiryDate: e.target.value });

    useEffect(() => {
        breadcrumbObject['Driver licence']['Driver Licence Number'] = driverDetails.driversLicenceNumber;
        breadcrumbObject['Driver licence']['Province'] = driverDetails.province;
        breadcrumbObject['Driver licence']['Driver Licence Expiry'] = driverDetails.driversLicenceExpiry;
        breadcrumbObject['Driver licence']['Date of Birth'] = driverDetails.dateOfBirth;
    }, [driverDetails]);

    const textBoxStyle: CSSProperties = {
        height: '5vh',
        borderRadius: '5px',
        border: '1px solid #C4C4C4',
        fontSize: '18px',
        justifyContent: 'left',
        margin: '1vh 0vw',
        boxSizing: 'border-box',
        width: '50%',
        left: '28px',
    };

    return (<>
        <div style={{ margin: '2.5vh 0vw' }}>
            <p>Please review the customer’s <strong>driver licence</strong> details, ensuring that each field is populated with the correct data.</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw' }}>
            <Label text={'Driver licence number'} requiredFieldInd={true} />
            <input
                style={textBoxStyle}
                name='driverLicenceNumber'
                defaultValue={driverDetails.driversLicenceNumber}
                required={true}
                onChange={onDriverLicenceChange}
            />
        </div>

        {driverDetails.country == Country.AU && (<>
            <div style={{ margin: '2.5vh 0vw' }}>
                <Label text='State' requiredFieldInd={true} />
                <DropDownMenu
                    menuName='stateDropMenu'
                    defaultVal={driverDetails.province
                        ? { label: driverDetails.province, value: driverDetails.province }
                        : dropDownPleaseSelect
                    }
                    required={true}
                    choices={[dropDownPleaseSelect].concat(
                        provinceByCountry[driverDetails.country].map((item: string) => (
                            { label: item, value: item, enabled: true }
                        )))
                    }
                    onSelect={onStateChange}
                />
            </div>
        </>)}

        <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw', width: '50%' }}>
            <Label text={'Driver licence expiry date'} requiredFieldInd={true} />
            <DatePicker
                name='expiryDate'
                max={maxDriverLicence}
                min={dateToISOFormat(currentDate)}
                defaultVal={driverDetails.driversLicenceExpiry}
                required={true}
                onChange={onExpiryDateChange} />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw', width: '50%' }}>
            <Label text={'Date of birth'} requiredFieldInd={true} />
            <DatePicker
                name='dateOfBirth'
                defaultVal={driverDetails.dateOfBirth}
                required={true}
                max={minimumAge}
                min={maximumAge}
                onChange={onDateOfBirthChange} />
        </div>

        {driverDetails.country == Country.GB && (<>
            <div style={{ margin: '2.5vh 0vw' }}>
                <Label text={'PCO license number'} requiredFieldInd={true} />
                <input
                    style={textBoxStyle}
                    name='pcoLicenseNumber'
                    defaultValue={driverDetails.pcoLicenseNumber}
                    required={true}
                    onChange={onPCOLicenseChange}
                />
            </div>
            <div style={{ margin: '2.5vh 0vw' }}>
                <Label text={'PCO license expiry date'} requiredFieldInd={true} />
                <DatePicker
                    name='pcoExpiryDate'
                    max={maxDriverLicence}
                    defaultVal={driverDetails.pcoLicenceExpiryDate}
                    required={true}
                    onChange={onPCOExpiryDateChange} />
            </div>
        </>)}
    </>
    );
};
