import React, { useState } from "react";

import Container from "./Container";
import infoIcon from '@assets/images/info_icon.svg';


type TooltipProps = {
    message: string,
    imgIconStyles?: object,
    innerWindowStyles?: object
};


export const Tooltip = (props: TooltipProps) => {

    const [showTooltipWindow, setShowTooltipWindow] = useState<boolean>(false);

    return (<>
        <img style={props?.imgIconStyles} src={infoIcon} onClick={() => setShowTooltipWindow(true)} />
        <div style={{ display: showTooltipWindow ? 'inline' : 'none', ...props?.innerWindowStyles}} onClick={() => setShowTooltipWindow(false)}>
            <Container>
                <p>{props.message}</p>
            </Container>
        </div>
    </>);
};
