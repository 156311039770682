import React, { useState, useEffect, CSSProperties } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

import { NavBar } from '@components/navigation/NavBar/NavBar';
import Button from '@components/buttons/Button/Button';
import { ConfirmationBox, WarningBox } from '@components/cards/messageBox';
import LoadingSpinner from '@components/spinners/Circular/Circular';
import { OnboardingService } from '@services/journeys/Onboarding';
import { Country } from '@utils/constants/localisation';
import * as Sentry from "@sentry/react";

export default function OnboardingResult() {
    const [searchParams] = useSearchParams();
    const key = searchParams.get('key') || '';  // TODO: maybe add some UI message if missing?

    const { custId, country } = useParams() as { custId: string, country: Country, key: string };

    const navigate = useNavigate();
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [responseReceived, setResponseReceived] = useState<boolean>(false);
    const [response, setResponse] = useState<any>();
    const [success, setSuccess] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<boolean>(false);
    const IMSTokenParams = { msalContext, isAuthenticated };

    let pageTitleText = success ? "Confirmation" : "Sorry, we ran into a problem";

    useEffect(
        () => {
            OnboardingService.postRedisKey(key, country, IMSTokenParams)
                .then((resp) => {
                    setResponse(resp)
                    if (resp.missingKey){
                        setRedirect(true)
                    }
                    setResponseReceived(true)
                    setSuccess(true)
                }).catch(() => {
                    setResponseReceived(true)
                    setSuccess(false)
                })
        }, []
    );
    useEffect(() => {
        if (redirect) {
            navigate('/')
        }
    })    

    const handleButtonClick = () => {
        navigate(`/customer/${country}/${custId}/overview`);
    };

    const pageStyle: CSSProperties = {
        margin: "2vh 2.5vw",
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 'calc(100vh - 100px)'
    };

    const buttonContainerStyle = {
        marginTop: 'auto',
    };

    const paragraphStyle = {
        marginTop: '2.5vh',
    };

    const SuccessPage = (props: { make: string, model: string, year: string, licencePlate: string, fleetioLink: string, zohoLink: string, email: string }) => {
        return (<>
            <ConfirmationBox message="Contract and vehicle assignment created"></ConfirmationBox>
            <p style={paragraphStyle}>
                The following vehicle has been assigned to the member in Fleetio: <strong>{props.licencePlate} - {props.year} {props.make} {props.model}</strong>. <a href={props.fleetioLink} target="_blank">View in Fleetio</a>
            </p>
            <p style={paragraphStyle}>
                A new subscription has been created in Zoho for the member. A signed contract PDF document has been generated and added to the member subscription. <a href={props.zohoLink} target="_blank">View in Zoho</a>
            </p>
            <p style={paragraphStyle}>
                A copy of the contract PDF has been emailed to <strong>{props.email}</strong>.
            </p>
        </>);
    };

    const FailurePage = () => {
        return (<>
            <WarningBox message="Something went wrong"></WarningBox>
            <p style={paragraphStyle}>We’re sorry, we were unable to process your request.</p>
            <p style={paragraphStyle}>Please try again later and if the issue persists, please log a ticket with IT Helpdesk.</p>
        </>);
    };

    return (<>
        {/* This conditional rendering is used to ensure that the navbar does not display on the loading screen */}
        {responseReceived && <NavBar pageTitle={pageTitleText} showButton={false} />}

        <div style={pageStyle}>
            {responseReceived
                ? (success
                    ? <SuccessPage {...response} />
                    : <FailurePage />)
                : <div style={{ textAlign: 'center', lineHeight: '100vh' }}><LoadingSpinner /></div>
            }
            <div style={buttonContainerStyle}>
                <Button label="Customer overview"
                        onClickFunc={handleButtonClick}
                        testId="onboarding-result-button"
                ></Button>
            </div>
        </div>
    </>);

};
