import React from 'react';
import CircularSpinner from "@components/spinners/Circular/Circular";

const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        <CircularSpinner />
    </div>
);

export default LoadingSpinner;
