import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import Label from "@components/inputs/text/Label";
import NumericInput from "@components/inputs/NumericInput";
import InfoBox from "@components/cards/messageBox/InfoBox";
import Button, { ButtonStyle } from "@components/buttons/Button/Button";
import DynamicTable, { TableRow } from "@components/Tables/addAncillaryTable";
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";
import useServiceHook from "@hooks/useServiceHook";
import SubscriptionService from "@services/subscription/SubscriptionService";
import DropDownModal from "./DropdownModal";
import { PlanOptions, splendInitiativesOptions } from "@utils/planOptions";
import { countrySpecificCurrency } from "@utils/misc/functions";
import { textBoxStyle } from '@utils/styles/textboxStyle';
import LoadingSpinner from "@utils/loadingSpinner";
import Warning from "@components/modal/info/Warning";


interface ChargesDetails {
    noticePeriodCharges: number;
    dailyFee: number;
    weeklyFee: number;
    minimumTermCharges: number;
    phTotalCharge: number;
    usageCharge: number;
    adminFee: number;
    state: string;
    acceptanceAndRemarketing?: number;
    startOdometer?: number;
    finishOdometer?: number;
    drivenDistance?: number;
    phWeeksTaken?: number;
    phTotalContribution?: number;
    phSubscriptionCharge?: number;
    minimumDaysToCharge?: number;
    noticeDaysToCharge?: number;
    errors: string[];
};

interface DriverDetails {
    country: string;
};

interface NoticePeriodChargesProps {
    chargesDetails: ChargesDetails;
    driverDetails: DriverDetails;
    handleNoticePeriodChargeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

interface MinimumPeriodChargesProps {
    chargesDetails: ChargesDetails;
    driverDetails: DriverDetails;
    handleMinimumPeriodChargeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

interface AcceptanceAndRemarketingChargesProps {
    chargesDetails: ChargesDetails;
    driverDetails: DriverDetails;
    handleAcceptanceRemarketingChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

interface PaymentHolidayChargesProps {
    chargesDetails: ChargesDetails;
    driverDetails: DriverDetails;
};

interface QParamObject {
    country: string;
    odometer: string;
    minimum_period_weeks?: string;
    fleetio_vehicle_id?: string;
    date_submitted_notice?: string;
    type: string;
    reason: string;
}

interface LocalTableRow {
    item: string;
    quantity: number;
    unit: number | null | undefined;
};

export default function CancellationChargesUK() {
    const cancellationRounding = 2;
    const ravPlanTypes = [PlanOptions.RAVCreditHire, PlanOptions.CancellationShareCover, PlanOptions.RAVShortTermRental];

    const navigate = useNavigate();
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { formState, updateFormState } = useUserJourneyContext();
    const { cancellationDetails, driverDetails, contractDetails, splendInitiatives, carInfo } = formState;

    const [tableData, setTableData] = useState<TableRow[]>([]);
    const [ancillaryCharges, setAncillaryCharges] = useState<number>(0);
    const [totalCharges, setTotalCharges] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [chargesServiceError, setChargesServiceError] = useState<string | null>(null);
    const [addonsDetails, setAddonsDetails] = useState<any>(null);
    const [addonsLoading, setAddonsLoading] = useState(true);
    const [totalEarlyCharges, setTotalEarlyCharges] = useState<number>(0)

    let qParamObject: QParamObject = {
        country: driverDetails.country,
        odometer: Math.floor(Number(cancellationDetails.endingKms)).toString(),
        fleetio_vehicle_id: carInfo.vehicleId,
        date_submitted_notice: cancellationDetails.noticeGiven ?? undefined,
        minimum_period_weeks: splendInitiatives.minimumPeriodWeeks ?? null,
        type: cancellationDetails.cancellationType,
        reason: cancellationDetails.cancellationCategory
    };

    if (splendInitiatives.splendInitiative === splendInitiativesOptions.DynamicPricing) {
        qParamObject = {
            ...qParamObject,
            minimum_period_weeks: "6",
        };
    }

    const cardStyle = {
        card: {
            border: '1px solid #ccc',
            borderRadius: '5px',
            marginBottom: '20px',
            padding: '20px',
            backgroundColor: '#fff',
        },
        header: {
            marginBottom: '10px',
            fontWeight: 'bold',
        },
    };

    const [chargesLoading, , , chargesDetails] = useServiceHook(
        SubscriptionService.getCancellationCharges,
        [qParamObject, contractDetails.subscriptionId, carInfo.vehicleId],
        true,
        [],
        "Please check that all the required fields are present on the Chargebee plan: minimum period, notice period, price, plan type and state."
    );

    useEffect(
        () => {
            // chargesDetails is an empty array in case of error from backend. No further execution in this case.
            if (Array.isArray(chargesDetails)) return;

            if (chargesDetails && chargesDetails.errors.length !== 0) {
                setChargesServiceError(chargesDetails.errors.join('<br>'));
                // The addon loading state is set to false because this flag is used to
                // conditionally render the content. With the error from cancellation
                // charges, the content becomes irrelevant
                setAddonsLoading(false);
            }
        },
        [chargesDetails]
    );

    // As it's needed to get the state from the getCancellationCharges call this addons call needs to be wrapped in a
    // useEffect so the chargesDetails are loaded from the getCancellationCharges call before this call is made.
    useEffect(() => {
        if (chargesDetails && chargesDetails.state) {
            const fetchAddons = async () => {
                try {
                    const addonsData = await SubscriptionService.getAddons(
                        {
                            country: driverDetails.country,
                            accounting_prefix: ["405", "406"].join(',')
                        },
                        { msalContext: msalContext, isAuthenticated: isAuthenticated }
                    );
                    setAddonsDetails(addonsData);
                    setAddonsLoading(false);
                } catch (error) {
                    setAddonsLoading(false);
                }
            };
            fetchAddons();
        }
    }, [chargesDetails]);

    const UsageCappedInfoNote = () => (
        <div style={{ margin: '1.5vh 3.5vw' }}>
            <InfoBox
                message='Usage is charged £500 per 3,000mi and capped at a maximum £2,500, leading to a total maximum fee of £3,250.' />
        </div>
    );

    const renderChargeSection = (label: any, value: any) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
                <Label text={label} styleCfg={{ bold: true }} />
            </div>
            <p style={{ margin: 0 }}>{value || ''}</p>
        </div>
    );

    const calculateEarlyCancellationCharge = () => {
        // A string is used instead of the PlanOptions Enum as when reading from the subscription card Flexi Own is used and not Splend Flexi-Own
        if (cancellationDetails.cancellationPlanType == PlanOptions.CancellationFlexiOwn) {
            return cancellationDetails.noticePeriodCharge + cancellationDetails.totalAcceptanceRemarketingFee;
        }
        return cancellationDetails.minimumPeriodCharge + cancellationDetails.noticePeriodCharge;
    };

    const validateAndSetDefault = (value: string, defaultValue: number = 0): number => {
        let newValue = parseFloat(value);
        if (isNaN(newValue) || newValue < 0 || newValue === null || newValue === undefined) {
            return defaultValue;
        }
        return newValue;
    };

    const handleMinimumPeriodChargeChange = (e: ChangeEvent<HTMLInputElement>) => (
        updateFormState(UserFormStates.cancellationDetails, { minimumPeriodCharge: validateAndSetDefault(e.target.value) })
    );

    const handleAcceptanceRemarketingChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newValue = validateAndSetDefault(e.target.value);
        updateFormState(UserFormStates.cancellationDetails, {
            acceptanceAndRemarketing: newValue,
            totalAcceptanceRemarketingFee: newValue
        });
    };

    const calculateTotal = (tableData: any) => tableData.reduce((total: any, row: any) => total + row.total, 0);

    useEffect(() => {
        const newAncillaryCharges = calculateTotal(tableData);
        setAncillaryCharges(newAncillaryCharges);

        let newEarlyCancellationCharges = calculateEarlyCancellationCharge();
        let newTotalCancellationCharges = newAncillaryCharges + newEarlyCancellationCharges;
        if (cancellationDetails.cancellationPlanType == PlanOptions.CancellationFlexiOwn && chargesDetails) {
            newTotalCancellationCharges = Number(newTotalCancellationCharges) + Number(chargesDetails?.phTotalCharge)
            newEarlyCancellationCharges = Number(newEarlyCancellationCharges) + Number(chargesDetails?.phTotalCharge)
        }
        setTotalCharges(newTotalCancellationCharges);
        updateFormState(UserFormStates.cancellationDetails, {
            totalEarlyCancellationCharges: newEarlyCancellationCharges,
            totalCancellationCharges: newTotalCancellationCharges,
            totalAncillaryCharges: newAncillaryCharges.toFixed(cancellationRounding),
            totalExtraCharges: newTotalCancellationCharges
        });

        // RAV Credit Hire and Share Cover do not have notice period, minimum term charges or payment holiday charges. However some customers who
        // were previously on Flexi Own subscriptions could still have ph charges on their CHB subscription so this may return a value. This is why these values
        // are manually overwritten.
        if (cancellationDetails.cancellationPlanType === PlanOptions.RAVCreditHire || cancellationDetails.cancellationPlanType === PlanOptions.CancellationShareCover) {
            updateFormState(UserFormStates.cancellationDetails, {
                noticePeriodCharge: 0,
                totalAcceptanceRemarketingFee: 0,
                phCharges: 0,
                totalCancellationCharges: newAncillaryCharges
            });
        }
    }, [chargesDetails, cancellationDetails.totalAcceptanceRemarketingFee, cancellationDetails.minimumPeriodCharge, cancellationDetails.noticePeriodCharge, tableData]);

    useEffect(() => {
        if (chargesLoading === false) {
            updateFormState(UserFormStates.cancellationDetails, {
                noticePeriodCharge: parseFloat(chargesDetails.noticePeriodCharges),
                dailySubscriptionFee: parseFloat(chargesDetails.dailyFee),
                minimumPeriodCharge: parseFloat(chargesDetails.minimumTermCharges),
                totalAcceptanceRemarketingFee: parseFloat(chargesDetails.minimumTermCharges),
                weeklySubscriptionFee: parseFloat(chargesDetails.weeklyFee),
                vehicleDepreciationFee: chargesDetails.usageCharge,
                acceptanceAndRemarketing: chargesDetails.adminFee,
                state: chargesDetails.state,
            });
            if (cancellationDetails.cancellationPlanType == PlanOptions.CancellationFlexiOwn) {
                updateFormState(UserFormStates.cancellationDetails, { phCharges: (chargesDetails.phTotalCharge) });
            }

            // set all notice, minimum period and payment holiday charges to 0 as they do not apply to RAV Credit Hire and Sharecover plans
            if (ravPlanTypes.includes(cancellationDetails.cancellationPlanType as PlanOptions)) {
                const ancillaryChargesNumber = ancillaryCharges.toFixed(cancellationRounding);
                updateFormState(UserFormStates.cancellationDetails, {
                    noticePeriodCharge: 0,
                    phCharges: 0,
                    totalCancellationCharges: Number(ancillaryChargesNumber)
                });
                if (cancellationDetails.cancellationPlanType !== PlanOptions.RAVShortTermRental) {
                    updateFormState(UserFormStates.cancellationDetails, { totalAcceptanceRemarketingFee: 0 });
                }
            }
        }
    }, [chargesDetails]);

    const handleSelection = (selectedOptions: TableRow) => setTableData(prevData => [...prevData, selectedOptions]);

    const handleRemoveRow = (index: number) => {
        setTableData(prevData => {
            const prevDataCpy = [...prevData];
            prevDataCpy.splice(index, 1);
            return prevDataCpy;
        });
    };

    const collectSelectedAddons = (tableData: LocalTableRow[]) => {
        const extractedData = tableData.map((row: { item: any; quantity: any, unit: any }) => {
            const addon = addonsDetails.find((addon: { name: any; id: any }) => addon.name === row.item);
            const unitPrice = !isNaN(row.unit) && row.unit !== null && row.unit !== undefined
                ? row.unit * 100
                : 0;
            if (!addon) {
                return null;
            }

            return {
                addon_id: addon.id,
                addon_description: addon.name,
                addon_quantity: row.quantity,
                addon_unit_price: unitPrice,
                addon_total: unitPrice * row.quantity,
                addon_pricing_model: addon.pricingModel
            };
        });

        // Filter out potential null values (addons not found)
        let validData = extractedData.filter((data: any) => data !== null);
        return validData;
    };

    const AcceptanceAndRemarketingCharges: React.FC<AcceptanceAndRemarketingChargesProps> = ({
        chargesDetails,
        driverDetails,
        handleAcceptanceRemarketingChange
    }) => (
        <>
            <div style={cardStyle.header}>Acceptance and remarketing fee and vehicle depreciation fee charges</div>
            <div style={cardStyle.card}>
                <p>Please confirm <strong>acceptance and remarketing fee and vehicle depreciation fee.</strong></p>
                <UsageCappedInfoNote />
                {renderChargeSection(`Starting miles`, chargesDetails.startOdometer)}
                {renderChargeSection(`Ending miles`, chargesDetails.finishOdometer)}
                {renderChargeSection(`Distance traveled (miles)`, chargesDetails.drivenDistance)}
                {renderChargeSection(`Usage charge (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.usageCharge)}
                <div style={{ margin: '2.5vh 0vw' }}>
                    {renderChargeSection(`Acceptance and remarketing fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.adminFee)}
                </div>
                <p>Total acceptance and remarketing and vehicle depreciation
                    charges {countrySpecificCurrency(driverDetails.country)}</p>
                <div style={{ margin: '2.5vh 0vw' }}>
                    <NumericInput
                        name="acceptance"
                        defaultVal={cancellationDetails.totalAcceptanceRemarketingFee}
                        required={true}
                        onBlur={handleAcceptanceRemarketingChange}
                        dataTestId="acceptanceInput"
                        style={textBoxStyle}
                    />
                </div>
            </div>
        </>
    );

    const PaymentHolidayCharges: React.FC<PaymentHolidayChargesProps> = ({ chargesDetails, driverDetails }) => (
        <>
            <div style={cardStyle.header}> Payment Holiday Charges</div>
            <div style={cardStyle.card}>
                {renderChargeSection(`Total weeks taken`, chargesDetails.phWeeksTaken)}
                {renderChargeSection(`Total payment holiday contribution`, chargesDetails.phTotalContribution)}
                {/* to be updated once charges have been worked out in the BE*/}
                {renderChargeSection(`Total payment holiday subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.phSubscriptionCharge)}
                <div style={{ margin: '2.5vh 0vw' }}>
                    {renderChargeSection(`Payment holiday charge (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.phTotalCharge)}
                </div>
            </div>
        </>
    );

    const MinimumPeriodCharges: React.FC<MinimumPeriodChargesProps> = ({
        chargesDetails,
        driverDetails,
        handleMinimumPeriodChargeChange
    }) => (
        <>
        <div style={cardStyle.card}>
                <div style={{ margin: '2.5vh 0vw' }}>
                    <p>Please confirm <strong>minimum period charges.</strong></p>
                </div>
                {renderChargeSection(`Weekly subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.weeklyFee)}
                {renderChargeSection(`Daily subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.dailyFee)}
                {renderChargeSection('# days charged for minimum period breach', chargesDetails.minimumDaysToCharge)}
                <div style={{ margin: '2.5vh 0vw' }}>
                    <div>
                        <Label text={`Minimum period charge (${countrySpecificCurrency(driverDetails.country)})`}
                            styleCfg={{ bold: true }} />
                    </div>
                    <NumericInput
                        name="minimum period"
                        defaultVal={cancellationDetails.minimumPeriodCharge}
                        required={true}
                        onBlur={handleMinimumPeriodChargeChange}
                        dataTestId="minPeriodInput"
                        style={textBoxStyle}
                    />
                </div>
            </div>
        </>
    );

    const NoticePeriodCharges: React.FC<NoticePeriodChargesProps> = ({
        chargesDetails,
        driverDetails,
        handleNoticePeriodChargeChange
    }) => (
        <>
            <div style={cardStyle.header}> Notice period charges</div>
            <div style={cardStyle.card}>
                <div style={{ margin: '2.5vh 0vw' }}>
                    <p>Please confirm <strong>notice period charges.</strong></p>
                </div>
                {renderChargeSection(`Weekly subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.weeklyFee)}
                {renderChargeSection(`Daily subscription fee (${countrySpecificCurrency(driverDetails.country)})`, chargesDetails.dailyFee)}
                {renderChargeSection('# days charged for notice period breach', chargesDetails.noticeDaysToCharge)}
                <div style={{ margin: '2.5vh 0vw' }}>
                    <div>
                        <Label text={`Notice period charge (${countrySpecificCurrency(driverDetails.country)})`}
                            styleCfg={{ bold: true }} />
                    </div>
                    <NumericInput
                        name="noticePeriodChargeInput"
                        style={textBoxStyle}
                        required
                        data-testid="noticePeriodChargeInput"
                        defaultVal={cancellationDetails.noticePeriodCharge}
                        onBlur={handleNoticePeriodChargeChange}
                    />
                </div>
            </div>
        </>
    );

    return (
        <>
            {addonsLoading
                ? <LoadingSpinner />
                : <>
                    <p>Please add any <strong>extra ancillary charges (e.g. damage).</strong></p>
                    <DynamicTable data={tableData} onRemoveRow={handleRemoveRow} />
                    {ancillaryCharges > 0 && (
                        renderChargeSection(
                            `Total ancillary charges (${countrySpecificCurrency(driverDetails.country)})`,
                            ancillaryCharges.toFixed(cancellationRounding))
                    )}
                    <div>
                        <div style={{ margin: '2.5vh 0vw' }}>
                            <Button
                                label="Add ancillary charge"
                                onClickFunc={() => setIsModalOpen(true)}
                                btnType={ButtonStyle.secondary}
                                testId='open-modal-button'
                            />
                        </div>

                        {isModalOpen &&
                            <DropDownModal
                                onClose={() => setIsModalOpen(false)}
                                options={addonsDetails}
                                onSelection={(selectedOptions: TableRow) => handleSelection(selectedOptions)} />
                        }
                    </div>
                    {ravPlanTypes.includes(cancellationDetails.cancellationPlanType as PlanOptions) ? (
                        <>
                            {cancellationDetails.cancellationPlanType === PlanOptions.RAVShortTermRental &&
                                <MinimumPeriodCharges
                                    chargesDetails={chargesDetails}
                                    driverDetails={driverDetails}
                                    handleMinimumPeriodChargeChange={handleMinimumPeriodChargeChange}
                                />
                            }
                        </>
                    ) : (
                        <div>
                            <NoticePeriodCharges
                                chargesDetails={chargesDetails}
                                driverDetails={driverDetails}
                                handleNoticePeriodChargeChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    updateFormState(UserFormStates.cancellationDetails, { noticePeriodCharge: validateAndSetDefault(e.target.value) })}
                            />

                            {cancellationDetails.cancellationPlanType !== PlanOptions.CancellationFlexiOwn && (
                                <MinimumPeriodCharges
                                    chargesDetails={chargesDetails}
                                    driverDetails={driverDetails}
                                    handleMinimumPeriodChargeChange={handleMinimumPeriodChargeChange}
                                />
                            )}

                            {cancellationDetails.cancellationPlanType == PlanOptions.CancellationFlexiOwn && (
                                <>
                                    <AcceptanceAndRemarketingCharges
                                        chargesDetails={chargesDetails}
                                        driverDetails={driverDetails}
                                        handleAcceptanceRemarketingChange={handleAcceptanceRemarketingChange}
                                    />
                                    <PaymentHolidayCharges
                                        chargesDetails={chargesDetails}
                                        driverDetails={driverDetails}
                                    />
                                </>
                            )}

                            <div style={{
                                margin: '2.5vh 0vw',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <div>
                                    <Label
                                        text={`Early cancellation charges (${countrySpecificCurrency(driverDetails.country)})`}
                                        styleCfg={{ bold: true }} />
                                </div>
                                <p style={{ margin: 0 }}>{cancellationDetails.totalEarlyCancellationCharges.toFixed(cancellationRounding) || 0}</p>
                            </div>

                            <div style={{ borderTop: '2px solid #000', padding: '10px 0', margin: '2.5vh 0vw' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <Label
                                            text={`Total extra charges (${countrySpecificCurrency(driverDetails.country)})`}
                                            styleCfg={{ bold: true }} />
                                    </div>
                                    <p style={{ margin: 0 }}>{totalCharges || 0}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {chargesServiceError && <Warning message='Something went wrong' additionalInfo={chargesServiceError}
                        onClickClose={() => navigate('/')} />}
                </>
            }
        </>
    );
}
