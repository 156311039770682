import React, { useEffect } from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';

import { textBoxStyle } from '@utils/styles/textboxStyle';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';
import { useNavigate } from 'react-router';

export default function CancellationPersonalDetails({ breadcrumbObject }: BreadcrumbProps) {

    const { formState, updateFormState } = useUserJourneyContext();
    const { personalInfo } = formState;

    const navigate = useNavigate();

    const handlePersonalInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case 'firstName':
                updateFormState(UserFormStates.personalInfo, { firstName: e.currentTarget.value });
                break;
            case 'middleName':
                updateFormState(UserFormStates.personalInfo, { middleName: e.currentTarget.value });
                break;
            case 'lastName':
                updateFormState(UserFormStates.personalInfo, { lastName: e.currentTarget.value });
                break;
        }
    };

    return (<>

        <p style={{ margin: '2.5vh 0' }}> Please review the customer's <strong>personal details</strong>, ensuring that each field is populated with the correct data.</p>
        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'First Name'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={'firstName'} style={textBoxStyle} required defaultValue={personalInfo.firstName} onChange={handlePersonalInfoChange} />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Middle Name'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={false}
            />
            <input name={'middleName'} style={textBoxStyle} defaultValue={personalInfo.middleName} onChange={handlePersonalInfoChange} />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Last Name'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={'lastName'} style={textBoxStyle} required defaultValue={personalInfo.lastName} onChange={handlePersonalInfoChange} />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Email'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={'email'} style={textBoxStyle} type='email' required defaultValue={personalInfo.email} disabled />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <p style={{ margin: '2.5vh 0' }}> If you need to change the email address, please do so directly in the CRM.</p>
        </div>

    </>)
};

