const cancellationReasons = [
    {
        "Type": "Voluntary (Customer Choice)",
        "Categories": [
            {
                "Category": "Leaving Rideshare Permanently",
                "Subcategories": [
                    { "Subcategory": "Earnings / Affordability" },
                    { "Subcategory": "Lifestyle" },
                    { "Subcategory": "Gained employment outside of rideshare" },
                    { "Subcategory": "Leaving the country" },
                    { "Subcategory": "Deactivated by Uber" },
                    { "Subcategory": "Visa Restrictions" }
                ]
            },
            {
                "Category": "Sourcing Vehicle Elsewhere",
                "Subcategories": [
                    { "Subcategory": "Purchasing own vehicle" },
                    { "Subcategory": "Competitor (company) - price / benefits / inclusions / plan options" },
                    { "Subcategory": "Competitor (company) - more suitable product / desired vehicle" },
                    { "Subcategory": "Competitor (private provider) - price / benefits / inclusions / plan options" },
                    { "Subcategory": "Competitor (private provider) - more suitable product / desired vehicle" }
                ]
            },
            {
                "Category": "Holiday / Travelling",
                "Subcategories": [
                    { "Subcategory": "Personal / Family", "ReturnDateField": true },
                    { "Subcategory": "Religious Holiday", "ReturnDateField": true},
                    { "Subcategory": "Moving State - Vehicle won't be required" },
                    { "Subcategory": "Moving State - I will need a new vehicle", "ReturnDateField": true}
                ]
            },
            {
                "Category": "Leaving Rideshare Temporarily",
                "Subcategories": [
                    { "Subcategory": "Other work / income - temporary", "ReturnDateField": true},
                    { "Subcategory": "Earnings / Affordability", "ReturnDateField": true },
                    { "Subcategory": "Rest and recovery needed", "ReturnDateField": true},
                    { "Subcategory": "Taking a break while quiet market", "ReturnDateField": true }
                ]
            }
        ]
    },
    {
        "Type": "Involuntary (Splend Choice)",
        "Categories": [
            {
                "Category": "Terminated by Splend",
                "Subcategories": [
                    { "Subcategory": "Safety Concerns - Speeding", "AddRestrictionTagField": true },
                    { "Subcategory": "Safety Concerns - Accident", "AddRestrictionTagField": true },
                    { "Subcategory": "Unpaid Damage Liability Waiver (DLW)", "AddRestrictionTagField": true },
                    { "Subcategory": "Unpaid subscription", "AddRestrictionTagField": true },
                    { "Subcategory": "Unreported vehicle damage", "AddRestrictionTagField": true },
                    { "Subcategory": "Deactivated by Uber", "AddRestrictionTagField": true },
                    { "Subcategory": "Lost drivers license", "AddRestrictionTagField": true },
                    { "Subcategory": "Other breach of contract", "AddRestrictionTagField": true }
                ]
            }
        ]
    },
    {
        "Type": "Internal Vehicle Transfers",
        "Categories": [
            { "Category": "Flexi to Flexi Own"},
            { "Category": "Flexi Own to Flexi Own"},
            { "Category": "Flexi Own to Flexi"},
            { "Category": "Vehicle Exchange"},
            { "Category": "Flexi Own Completion"}
        ]
    }
];

export default cancellationReasons;