import React, { useEffect } from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import DropDownMenu, { IChoice } from "@components/inputs/DropDownMenu";
import nationalities from '../../SmartOpsHome/nationalities';
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';
import { Country } from '@utils/constants/localisation';


/**
 * View intended to be integrated into the Flexi onboarding form.
 *
 * All inputs are stored into the corresponding section of the Flexi Own context.
 *
 * All inputs default values are taken from the context. All information
 * related to the driver should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has
 * to manually write all of them.
 */
export default function FlexiPersonalDetails({ breadcrumbObject }: BreadcrumbProps) {

    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };

    const sexOptions: IChoice[] = [
        { value: 'Male', label: 'Male', enabled: true },
        { value: 'Female', label: 'Female', enabled: true },
        { value: 'Other', label: 'Other', enabled: true },
        { value: 'Do not wish to disclose', label: 'Do not wish to disclose', enabled: true },
    ];

    const { formState, updateFormState } = useUserJourneyContext();
    const { personalInfo, driverDetails: { country } } = formState;

    const defaultNationality: IChoice = nationalities.find((nationality: IChoice) => nationality.label === personalInfo.nationality)
        || (country === Country.AU
            ? nationalities.filter((nationality: IChoice) => nationality.label === 'Australian')[0]
            : nationalities.filter((nationality: IChoice) => nationality.label === 'British')[0]);
    const defaultGender: IChoice = sexOptions.find((gender: IChoice) => gender.value === personalInfo.gender) || sexOptions[0];

    useEffect(() => {
        updateFormState(UserFormStates.personalInfo, {
            gender: defaultGender.value,
            nationality: defaultNationality.value
        });
    }, []);

    const handlePersonalInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case 'firstName':
                updateFormState(UserFormStates.personalInfo, { firstName: e.currentTarget.value });
                break;
            case 'middleName':
                updateFormState(UserFormStates.personalInfo, { middleName: e.currentTarget.value });
                break;
            case 'lastName':
                updateFormState(UserFormStates.personalInfo, { lastName: e.currentTarget.value });
                break;
            case 'email':
                updateFormState(UserFormStates.personalInfo, { email: e.currentTarget.value });
                break;
            case 'genderDropMenu':
                updateFormState(UserFormStates.personalInfo, { gender: e.currentTarget.value });
                break;
            case 'nationality':
                updateFormState(UserFormStates.personalInfo, { nationality: e.currentTarget.value });
                break;
            case 'hirerABN':
                updateFormState(UserFormStates.personalInfo, { hirerABN: e.currentTarget.value });
                break;
            case 'nationalInsuranceNumber':
                updateFormState(UserFormStates.personalInfo, { nationalInsuranceNumber: e.currentTarget.value });
                break;
        }
    };

    useEffect(() => {
        breadcrumbObject['Personal details']['Customer First Name'] = personalInfo.firstName;
        breadcrumbObject['Personal details']['Customer Middle Name'] = personalInfo.middleName;
        breadcrumbObject['Personal details']['Customer Last Name'] = personalInfo.lastName;
        breadcrumbObject['Personal details']['Customer Email'] = personalInfo.email;
        breadcrumbObject['Personal details']['Customer Gender'] = personalInfo.gender;
        breadcrumbObject['Personal details']['Hirer ABN'] = personalInfo.hirerABN;
        breadcrumbObject['Personal details']['Customer Nationality'] = personalInfo.nationality;
    }, [personalInfo]);

    const restrictInputTo11NumberCharacters = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;

        // Remove non-numeric characters
        value = value.replace(/[^0-9]/g, '');

        // Ensure the value is not longer than 11 characters
        if (value.length > 11) {
            value = value.slice(0, 11);
        }

        event.target.value = value;
    };

    const handleInputNI = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;

        // Remove non-alphanumeric characters.
        value = value.replace(/[^a-zA-Z0-9]/g, '');
        // Ensure the value is not longer than 9 characters.
        value = value.slice(0, 9);
        event.target.value = value;

        // Solution inspired from here https://stackoverflow.com/a/45695707
        // If input length is not matched, then put a custom error message on the input
        // to be shown when the validation kicks in (e.g. form submit). Remove it for
        // valid length, so the error/warning message will not continue to be displayed.
        if (event.target.value.length !== 9) {
            event.target.setCustomValidity("National insurance number must be exactly 9 characters long!");
            return;
        }
        event.target.setCustomValidity('');
    };

    return (<>
        <p style={{ margin: '2.5vh 0' }}> Please review the customer's <strong>personal details</strong>, ensuring that each field is populated with the correct data.</p>
        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'First Name'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={'firstName'} style={textBoxStyle} required defaultValue={personalInfo.firstName} onChange={handlePersonalInfoChange} />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Middle Name'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={false}
            />
            <input name={'middleName'} style={textBoxStyle} defaultValue={personalInfo.middleName} onChange={handlePersonalInfoChange} />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Last Name'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={'lastName'} style={textBoxStyle} required defaultValue={personalInfo.lastName} onChange={handlePersonalInfoChange} />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Email'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <input name={'email'} style={textBoxStyle} type='email' required defaultValue={personalInfo.email} disabled />
        </div>

        <p style={{ margin: '2.5vh 0' }}> If you need to change the email address, please do so directly in the CRM.</p>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Sex'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <DropDownMenu
                menuName='genderDropMenu'
                defaultVal={defaultGender}
                required={true}
                choices={sexOptions}
                onSelect={handlePersonalInfoChange}
            />
        </div>

        <div style={{ margin: '2.5vh 0' }}>
            <Label
                text={'Nationality'}
                styleCfg={{ largeFont: false, bold: false }}
                requiredFieldInd={true}
            />
            <DropDownMenu
                menuName='nationality'
                defaultVal={defaultNationality}
                required={true}
                choices={[dropDownPleaseSelect, ...nationalities]}
                onSelect={handlePersonalInfoChange}
            />
        </div>

        {country == Country.AU && (<>
            <div style={{ margin: '2.5vh 0' }}>
                <Label
                    text={'Hirer ABN (must be 11 digits)'}
                    styleCfg={{ largeFont: false, bold: false }}
                    requiredFieldInd={true}
                />
                <input name={'hirerABN'}
                    pattern="\d{11}"
                    type='text'
                    style={textBoxStyle}
                    required
                    defaultValue={personalInfo.hirerABN}
                    onInput={restrictInputTo11NumberCharacters}
                    onChange={handlePersonalInfoChange} />
            </div>
        </>)}

        {country == Country.GB && (<>
            <div style={{ margin: '2.5vh 0' }}>
                <Label
                    text={'National Insurance Number'}
                    styleCfg={{ largeFont: false, bold: false }}
                    requiredFieldInd={true}
                />
                <input
                    name={'nationalInsuranceNumber'}
                    style={textBoxStyle}
                    required
                    defaultValue={personalInfo.nationalInsuranceNumber}
                    onChange={handlePersonalInfoChange}
                    onInput={handleInputNI}
                />
            </div>
        </>)}
    </>);
};
