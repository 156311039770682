import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import customerDetails from "@services/customer/CustomerDetails"
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {useAPIError} from "context/ServerErrorContext";
import Warning from '@components/modal/info/Warning';
import {UserFormStates, useUserJourneyContext} from 'context/UserJourneyContext';
import {msalInstance} from 'SmartOpsHome';
import {Country} from '@utils/constants/localisation';


export interface ICustomerDetails {
    crmId: string,
    lastName: string,
    middleName: string,
    firstName: string,
    email: string,
    memberStatus: string,
    blacklisted: boolean,
    tag: string,
    province: string,
    driversLicenceNumber: string,
    dateOfBirth: string,
    driversLicenceExpiry: string,
};


interface result {
    loading: boolean,
    error: boolean,
    errMessage?: string,
    customer: ICustomerDetails,
};


export default function (custId: string, country: string): result {
    const navigate = useNavigate();
    const { updateFormState } = useUserJourneyContext()

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const [customer, setCustomer] = useState<ICustomerDetails>({
        crmId: 'Loading...', lastName: 'Loading...', firstName: 'Loading...',
        email: 'Loading...', memberStatus: 'Loading...', blacklisted: false,
        tag: 'Loading...', province: 'Loading...', driversLicenceNumber: 'Loading...',
        dateOfBirth: 'Loading...', driversLicenceExpiry: 'Loading...',
        middleName: 'Loading...',
    });
    const { setErr, removeErr } = useAPIError();

    // Get MSAL context and authentication status for Smart Ops API Auth
    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const treat5xxServerErr = () => {
        setCustomer(customer);
        setLoading(false);
        setError(true);
        setErrMessage('Server error! Please contact administrator.')
    };

    useEffect(() => {
        setLoading(true);
        setError(false);
        setErrMessage('');
        customerDetails(
            custId,
            country,
            { msalContext, isAuthenticated }
        ).then(
            (response) => {
                // TODO: 4xx statuses handling if necessary
                if (response?.response?.status >= 500) {
                    treat5xxServerErr();
                    return;
                } else if (response?.status === 204) {
                    setCustomer({
                        crmId: 'Not Found', lastName: 'Not Found', firstName: 'Not Found',
                        email: 'Not Found', memberStatus: 'Not Found', blacklisted: true,
                        tag: 'Not Found', province: 'Not Found', driversLicenceNumber: 'Not Found',
                        dateOfBirth: 'Not Found', driversLicenceExpiry: 'Not Found',
                        middleName: 'Not Found'
                    });
                    setLoading(false);
                    return;
                }
                const customerData = response.data;

                // These are used to supply the UserJourneyProvider with the initial information for the customer.
                updateFormState(UserFormStates.personalInfo, {
                    firstName: customerData.first_name,
                    middleName: customerData.middle_name,
                    lastName: customerData.last_name,
                    email: customerData.email,
                    alternativeName: customerData.alternative_name,
                    nationality: customerData.nationality,
                    hirerABN: customerData.abn,
                    companyName: customerData.Company_Name,
                    companyABN: customerData.Company_ABN,
                    tollTagIssuer: customerData.toll_tag_issuer,
                    tollTagAccountNumber: customerData.toll_tag_account_number,
                    tollTagNumber: customerData.toll_tag_number,
                    gender: customerData.gender,
                    mobile: customerData.mobile,
                    nationalInsuranceNumber: customerData.National_Insurance_Number,
                });

                updateFormState(UserFormStates.driverDetails, {
                    blacklisted: customerData.blacklisted,
                    tag: customerData.tag,
                    plan: '',  // value will be filled by the user
                    province: customerData.licence_state,
                    driversLicenceNumber: customerData.driver_licence_number,
                    dateOfBirth: customerData.dob,
                    driversLicenceExpiry: customerData.licence_expiry,
                    crmId: customerData.id,
                    country: country as Country,
                    pcoLicenseNumber: customerData.PCO_License_Number,
                    pcoLicenceExpiryDate: customerData.PCO_License_Expiry_Date,
                });

                updateFormState(UserFormStates.addressInfo, {
                    street: customerData.street,
                    suburb: customerData.suburb,
                    city: customerData.city,
                    state: customerData.state,
                    postcode: customerData.postcode,
                });

                updateFormState(UserFormStates.emergencyContactInfo, {
                    primaryName: customerData.primary_emergency_name,
                    primaryAddress: customerData.primary_emergency_address,
                    primaryPhone: customerData.primary_emergency_phone,
                    primaryRelationship: customerData.primary_emergency_relationship,
                    secondaryName: customerData.secondary_emergency_name,
                    secondaryAddress: customerData.secondary_emergency_address,
                    secondaryPhone: customerData.secondary_emergency_phone,
                    secondaryRelationship: customerData.secondary_emergency_relationship,
                });

                const getLoggedInUser = () => {
                    const userDetails = msalInstance.getActiveAccount();
                    return { name: userDetails?.name, email: userDetails?.username }
                }

                const userDetails = getLoggedInUser()

                updateFormState(UserFormStates.contractDetails, {
                    contactOwner: userDetails.name,
                    contactOwnerEmail: userDetails.email?.toLowerCase(),
                    planLen: 0,
                    planId: '',
                    planSetupFee: null,
                    planName: '',
                    planWeeklyFee: 0,
                    planWeeklyDistAllowance: 0,
                    planAdditionalDistCharge: 0,
                    planMinimumPeriod: 0,
                });

                updateFormState(UserFormStates.usedVehicleAddendum, {
                    scheduledIntervalServices: 0,
                    replacementTyres: 0,
                    replacementBrakePads: 0,
                    replacementOrMachined: 0,
                    numberOfDiscountedWeeks: 0,
                    reducedSubscriptionTerm: 0,
                });

                updateFormState(UserFormStates.splendInitiatives, {
                    weeklyDistanceAllowance: customerData.Weekly_Kilometre_Allowance,
                    minimumPeriodWeeks: customerData.Minimum_Period, //This is named differently in Zoho however cannot be changed as there is already customer data stored to the field
                    splendInitiative: customerData.Splend_Initiative,
                    tripCount: customerData.Trip_Discount,
                    vehicleUtilisation: customerData.Available_Vehicle_Discount,
                });

                setCustomer(() => {
                    // eslint-disable-next-line camelcase
                    if (!customerData.member_status) { customerData.member_status = 'No Status' }
                    return {
                        crmId: customerData.id, email: customerData.email, firstName: customerData.first_name,
                        lastName: customerData.last_name, memberStatus: customerData.member_status,
                        blacklisted: customerData.blacklisted, tag: customerData.tag, province: customerData.province,
                        driversLicenceNumber: customerData.drivers_licence_number, dateOfBirth: customerData.dob,
                        driversLicenceExpiry: customerData.drivers_licence_expiry, middleName: customerData.middle_name,
                        hasAltName: customerData.has_alt_name, alternativeName: customerData.alternative_name,
                        nationality: customerData.nationality, hirerABN: customerData.abn, companyName: customerData.Company_Name,
                        companyABN: customerData.Company_ABN, tollTagIssuer: customerData.toll_tag_issuer,
                        tollTagAccountNumber: customerData.toll_tag_account_number, tollTagNumber: customerData.toll_tag_number,
                        gender: customerData.gender, street: customerData.street, suburb: customerData.suburb, city: customerData.city,
                        state: customerData.state, postcode: customerData.postcode,
                    }
                });
            }).catch((err) => {
                setCustomer(customer);
                setLoading(false);
                setError(true);
                setErr(
                    true,
                    {
                        msg: 'Error in CustomerDetails',
                        // If there is an error from the backend, upon click reset err state and redirect user to homepage 
                        UI: <WarningModal onClickClose={() => { removeErr(); navigate('/'); }} />
                    });
            });
    }, [custId]);

    return { loading, error, errMessage, customer };
};


const WarningModal = ({ onClickClose }: { onClickClose: React.MouseEventHandler }) => (
    <Warning
        message="Something went wrong"
        additionalInfo={`We’re sorry, we were unable to process your request.
        We will investigate the issue and in the meantime, please onboard the customer via an alternative method`}
        onClickClose={onClickClose}
    />
);
