import React, {CSSProperties, useEffect} from 'react';

import Label from '@components/inputs/text/Label';
import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import { discountedWeeks } from '@utils/misc/discountedWeeks';
import { textBoxStyle } from '@utils/styles/textboxStyle';

/**
 * View intended to be integrated into the Splend Rent to Own - Used Vehicle onboarding form.
 *
 * All inputs are stored into the corresponding section of the UserJourney context.
 *
 * All inputs default values are taken from the context. All information
 * related to the vehicle should've been loaded by the time the user gets
 * to this page. If not, it is not a critical issue, but the user has
 * to manually write all of them.
 */
export default function FlexiOwnUsedVehicleAddendum() {

    const { formState, updateFormState } = useUserJourneyContext();
    const { usedVehicleAddendum, contractDetails } = formState;


    const onUsedVehicleInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.currentTarget.name) {
            case 'numberOfDiscountedWeeks':
                updateFormState(UserFormStates.usedVehicleAddendum, { numberOfDiscountedWeeks: parseInt(e.currentTarget.value) });
                updateFormState(UserFormStates.usedVehicleAddendum, { reducedSubscriptionTerm: calculateReducedSubscriptionTerm(e.currentTarget.value) });
                break;
            case 'scheduledInterval':
                updateFormState(UserFormStates.usedVehicleAddendum, { scheduledIntervalServices: parseInt(e.currentTarget.value) });
                break;
            case 'replacementTyres':
                updateFormState(UserFormStates.usedVehicleAddendum, { replacementTyres: parseInt(e.currentTarget.value) });
                break;
            case 'replacementBrakePads':
                updateFormState(UserFormStates.usedVehicleAddendum, { replacementBrakePads: parseInt(e.currentTarget.value) });
                break;
            case 'replacementOrMachined':
                updateFormState(UserFormStates.usedVehicleAddendum, { replacementOrMachined: parseInt(e.currentTarget.value) });
                break;
        }
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
    
        // Remove non-numeric characters
        value = value.replace(/[^0-9]/g, '');
    
        event.target.value = value;
    };

    const calculateReducedSubscriptionTerm =(weeks: string) => {
        const intWeeks = parseInt(weeks) || 0;
        return (contractDetails.planLen * 52) - intWeeks
    }

    useEffect(() => {
        // Calculate the initial reduced subscription term (using 0 weeks from context default)
        updateFormState(UserFormStates.usedVehicleAddendum, { reducedSubscriptionTerm:
                calculateReducedSubscriptionTerm(usedVehicleAddendum.numberOfDiscountedWeeks.toString()) });
    }, []);

    const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault(); // Prevents the default behavior (e.g., clearing the selection)
        const inputElement = event.currentTarget;
        inputElement.select(); // Selects all text in the input field
      };

    const dropDownPleaseSelect: IChoice = { label: '- please select -', value: '', enabled: false };
    return (<>
        <div style={{ margin: '2.5vh 0vw' }}>
            {/* to be saved to zoho */}
            <Label text={'# of Discounted Weeks reducing the Subscription Term'} requiredFieldInd={true}/> 
            <DropDownMenu
                menuName='numberOfDiscountedWeeks'
                defaultVal={discountedWeeks.some(item => parseInt(item.value) === usedVehicleAddendum.numberOfDiscountedWeeks)
                    ? { label: usedVehicleAddendum.numberOfDiscountedWeeks.toString(), value: usedVehicleAddendum.numberOfDiscountedWeeks.toString() }
                    : dropDownPleaseSelect}
                required={true}
                choices={[dropDownPleaseSelect, ...discountedWeeks.map((item: any) => ({ label: item.label, value: item.value, enabled: true }))]}
                
                onSelect={onUsedVehicleInfoChange}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw', display: 'flex', justifyContent: 'space-between', width: '50%' }}>
            {/* to be saved to zoho */}
            <div><strong>Reduced Subscription Term</strong></div>
            <div>{usedVehicleAddendum.reducedSubscriptionTerm}</div>

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

        <p><strong>Applicable Maintenance Inclusions</strong></p>

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Scheduled Interval Services'} />
            <input
                style={textBoxStyle}
                name='scheduledInterval'
                type="number"
                max={12}
                defaultValue={usedVehicleAddendum.scheduledIntervalServices}
                required={true}
                onInput={handleInput}
                onChange={onUsedVehicleInfoChange}
                onClick={handleInputClick}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Replacement tyres'}/>
            <input
                style={textBoxStyle}
                name='replacementTyres'
                type="number"
                max={12}
                defaultValue={usedVehicleAddendum.replacementTyres}
                required={true}
                onInput={handleInput}
                onChange={onUsedVehicleInfoChange}
                onClick={handleInputClick}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Replacement brake pads'} />
            <input
                style={textBoxStyle}
                name='replacementBrakePads'
                type="number"
                max={12}
                defaultValue={usedVehicleAddendum.replacementBrakePads}
                onInput={handleInput}
                onChange={onUsedVehicleInfoChange}
                onClick={handleInputClick}
            />

        </div>

        <div style={{ margin: '2.5vh 0vw' }}>

            <Label text={'Replacement or machined brake discs'} />
            <input
                style={textBoxStyle}
                name='replacementOrMachined'
                type="number"
                max={12}
                defaultValue={usedVehicleAddendum.replacementOrMachined}
                onInput={handleInput}
                onChange={onUsedVehicleInfoChange}
                onClick={handleInputClick}
            />

        </div>

    </>

    );
};
