import React, { CSSProperties, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useServiceHook from "hooks/useServiceHook";
import { SubscriptionService } from "@services/index";
import SubscriptionsListPanel from '@components/panels/SubscriptionsList/SubscriptionsList';
import {
    flexiOwnPlanNames,
    flexiOwnUsedPlanNames,
    ravCreditHirePlanNames,
    ravShareCoverPlanNames,
    SubscriptionPlan
} from "@utils/constants/products";
import { NavBar } from "@components/navigation/NavBar/NavBar";
import { ISubscriptionSummaryItem } from "@components/cards/SubscriptionSummary/SubscriptionSummary";
import CircularSpinner from "@components/spinners/Circular/Circular";
import { InfoBox } from '@components/cards/messageBox';
import { SubscriptionsStatus } from "@utils/constants/products";
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";


export default function AdditionalDriverSubscriptionsList() {
    const navigate = useNavigate();
    const { custId, country } = useParams();
    const { updateFormState, resetFormState } = useUserJourneyContext();

    useEffect(
        () => {
            resetFormState(UserFormStates.carInfo);
            updateFormState(UserFormStates.driverDetails, { plan: '' });
        }, []
    );

    // loading and error SHALL NOT be true in the same time
    const [loading, , , results] = useServiceHook(SubscriptionService.getSubscriptions, [custId, country]);

    const parseSubsPlan = (sub: ISubscriptionSummaryItem[]) => {  // assign standard plan name to each subscription item
        return sub.map((sub: ISubscriptionSummaryItem) => {
            if (flexiOwnPlanNames.includes(sub.plan.toLowerCase())) { sub.plan = SubscriptionPlan.flexiOwn; }
            else if (flexiOwnUsedPlanNames.includes(sub.plan)) { sub.plan = SubscriptionPlan.flexiOwnUsed; }
            else if (ravShareCoverPlanNames.includes(sub.plan)) { sub.plan = SubscriptionPlan.ravShareCover; }
            else if (ravCreditHirePlanNames.includes(sub.plan)) { sub.plan = SubscriptionPlan.ravCreditHire; }
            else { sub.plan = SubscriptionPlan.flexi; }
            return sub;
        });
    };

    const selectSubscriptionLabelStyle: CSSProperties = {
        margin: '0',  // set to 0 to mitigate p tag's default margin value
        padding: '2vh 0 2vh 2vw',
        color: '#2C2A2D',
        fontWeight: '400',
        borderBottom: '1px solid #C4C4C4',
    };

    const subscriptions = loading === false ? parseSubsPlan(results) : [];

    const renderSubscriptionListPanel = () => {
        if (subscriptions.length === 0) {
            return <NoSubscriptionsInfoNote />;
        }

        return (
            <SubscriptionsListPanel
                keyName='onboarding-subscriptions'
                items={subscriptions.map((subs) => ({
                    subsId: subs.subsId,
                    startDate: subs.startDate,
                    carDetails: subs.carDetails,
                    registration: subs.registration,
                    plan: subs.plan,
                    status: subs.status,
                    onClickFn: subs.status == SubscriptionsStatus.active ? () => handleCardClick(subs.subsId, subs.plan) : undefined
                }))}
            />
        );
    };

    const handleCardClick = (subsId: any, plan: any) => {
        updateFormState(UserFormStates.additionalDriver, { subsID: subsId });
        updateFormState(UserFormStates.additionalDriver, { plan: plan });
        navigate('info');
    };

    return (<>
        <NavBar pageTitle="Select subscription" />
        <p style={selectSubscriptionLabelStyle}>Select a Subscription where you would like to add/update an additional driver</p>
        {loading === true
            ? <LoadingSpinner />
            : renderSubscriptionListPanel()
        }
    </>);
};


const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        <CircularSpinner />
    </div>
);


const NoSubscriptionsInfoNote = () => (
    <div style={{ margin: '1.5vh 3.5vw' }}>
        <InfoBox message='Please note that the customer does not have any subscriptions on their record' />
    </div>
);
