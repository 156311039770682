import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import VehicleDetails from "@components/panels/VehicleDetails";
import DropDownMenu, { IChoice } from "@components/inputs/DropDownMenu";
import Label from "@components/inputs/text/Label";
import useServiceHook from "@hooks/useServiceHook";
import Elliptical from "@components/spinners/Elliptical/Elliptical";
import SubmitBtn from "@components/buttons/FormButtons/SubmitBtn";
import { IVehicle, VehicleService } from "@services/Vehicles/Vehicles";
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";
import { Country } from "@utils/constants/localisation";
import { NavBar } from "@components/navigation/NavBar/NavBar";
import { PlanOptions } from "@utils/planOptions";
import * as Sentry from "@sentry/react";
import breadcrumbObject from "../../SmartOpsHome/breadcrumbObject";

import WarningBox from "@components/cards/messageBox/WarningBox";
import { countrySpecificDistanceUnit } from "@utils/misc/functions";

/**
 * View intended to be be gateway into onboarding form.
 * 
 * All inputs are stored into the corresponding section of the User Journey context.
 * 
 * The plan and hub are the only fields that are not available in advance inside
 * the context, so they will have their default values (set during the context
 * initialisation phase).
 * 
 * Each plan/hub change will trigger a service call to get the corresponding
 * vehicles (being the dependencies for the useServiceHook).
 * 
 * When a car is selected, its details will be automatically shown, the
 * odometer being the only editable field. Any update will be saved to the
 * context.
 * 
 * This is a 2 ways page, meaning that the user can get to it coming from
 * customer search or from a next step within the form. For the 1st case,
 * the User context - carInfo would be mostly empty, whereas for the 2nd
 * case, it would be filled with the values. Also on the 1st case, given
 * the emptiness of the context, the states are initialised regardless of the
 * context, whereas in the 2nd case, given the filled context, the states are
 * initialised by using the info from the context. That's the reason behind 
 * the 'circular' context and state plan&hub initialisations.
 * 
 * OBS!! 
 * 1. If the odometer value had been changed, the user navigates to a next
 * step and comes back, the value from the context will be used as a default
 * value for the odometer. However if he changes the vehicle but in the end
 * he ends up with the same one, the odometer value will be the one retrieved
 * from the server, not the one the user wrote.
 * 
 * 2. Plans/hubs are hardcoded on the client side.
 */
export default function VehicleAssignment() {
    const planOptions: { [key in Country]: IChoice[] } = {
        GB: [
            { value: PlanOptions.UKFlexiOwn, label: PlanOptions.UKFlexiOwn, enabled: true },
            { value: PlanOptions.UKFlexi, label: PlanOptions.UKFlexi, enabled: false },
            { value: PlanOptions.UKRentToOwnUsed, label: PlanOptions.UKRentToOwnUsed, enabled: true },
        ],
        AU: [
            { value: PlanOptions.SplendFlexiOwn, label: PlanOptions.SplendFlexiOwn, enabled: true },
            { value: PlanOptions.SplendFlexi, label: PlanOptions.SplendFlexi, enabled: true },
            { value: PlanOptions.SplendRentToOwnUsedVehicle, label: PlanOptions.SplendRentToOwnUsedVehicle, enabled: true },
            { value: PlanOptions.RAVCreditHire, label: PlanOptions.RAVCreditHire, enabled: true },
            { value: PlanOptions.RAVSharecover, label: PlanOptions.RAVSharecover, enabled: true },
            { value: PlanOptions.RAVShortTermRental, label: PlanOptions.RAVShortTermRental, enabled: true },
        ]
    };

    const hubs: { [key in Country]: IChoice[] } = {
        GB: [
            { value: 'Cricklewood', label: 'Cricklewood', enabled: true },
            { value: 'Tower Bridge', label: 'Tower Bridge', enabled: true },
        ],
        AU: [
            { value: 'ACT', label: 'ACT', enabled: true },
            { value: 'NSW', label: 'NSW', enabled: true },
            { value: 'QLD', label: 'QLD', enabled: true },
            { value: 'SA', label: 'SA', enabled: true },
            { value: 'VIC', label: 'VIC', enabled: true },
            { value: 'WA', label: 'WA', enabled: true },
        ]
    };

    const noVehicleDetails: IVehicle = {
        id: '', make: '', model: '', year: '', colour: '',
        license_plate: '', vin: '', body_type: '', odometer: 0,
    };

    // Get existing info available in various places (route, context, etc)
    const { country } = useParams() as { country: Country };
    const { formState, updateFormState, resetFormState } = useUserJourneyContext();
    const { carInfo, driverDetails, contractDetails, personalInfo } = formState;
    const componentName = 'Vehicle assignment'
    const hubOrState = country == Country.GB ? 'hub' : 'state'

    // Flag used to prevent service to run via dedicated hook until all necessary parameters are set,
    // this way preventing multiple calls. If info is available through context, then the hook can run.
    const [allowServiceRun, setAllowServiceRun] = useState<boolean>(!!(driverDetails.plan && carInfo.hub));

    //Used to decide whether to show blank or value in the odometer field
    const [showOdometer, setShowOdometer] = useState<boolean>(false)

    // Used to instantiate the warning box for when users enter a lower odometer value than the current
    // Fleetio odometer value
    const [odometerCheck, setOdometerCheck] = useState<boolean>(false)
    const [startingOdometer, setStartingOdometer] = useState<number>(0)

    // plan state defaulted to the value from context, if any; if not, then
    // set the first value from the available plans
    const [plan, setPlan] = useState<IChoice>(
        driverDetails.plan
            ? planOptions[country].filter(item => item.value === driverDetails.plan)[0]
            : planOptions[country][0] //changed as RAV has been removed as an option
    );

    // hub is defaulted to the value from the context, if any; if not, then
    // set the first value from the available hubs.
    const [hub, setHub] = useState<IChoice>(
        carInfo.hub
            ? hubs[Country[country]].filter(item => item.value === carInfo.hub)[0]
            : hubs[Country[country]][0]
    );

    // At rendering:
    // 1. set the context's plan value with the value of state - plan.
    // 2. set the context's hub with value of state - hub.
    // At this stage the plan and the hub have been set inside the context 
    // and as states so the service can be triggered safely, so last step is
    // setting the flag that allows the service to run -> having it false before,
    // it prevents the service to run twice, the first time being with the
    // hub parameter not set, resulting into a backend fail
    useEffect(
        () => {
            updateFormState(UserFormStates.driverDetails, { plan: plan.value });
            updateFormState(UserFormStates.driverDetails, { country: country });
            updateFormState(UserFormStates.carInfo, { hub: hub.value });
            setAllowServiceRun(true);

            if (carInfo.make) {
                updateFormState(UserFormStates.carInfo, {
                    id: carInfo.id, make: carInfo.make, model: carInfo.model,
                    year: carInfo.year, colour: carInfo.colour, registrationPlate: carInfo.registrationPlate, vin: carInfo.vin,
                    bodyType: carInfo.bodyType, odometer: carInfo.odometer
                })
            }
            else { noVehicleDetails }

            // If a vehicle has already been selected then progressed to another stage of the form
            // this line ensures that the odometer value will show when returning to Vehicle Assignment
            // page.
            if (carInfo.id !== "") {
                setShowOdometer(true)
            }
        },
        []
    );

    const navigate = useNavigate();

    // Vehicle state - initialised with the values from the context if any,
    // otherwise with the vehicle object which denotes no vehicle selected
    const [selectedVehDetails, setSelectedVehDetails] = useState<IVehicle>(
        carInfo.make
            ? {
                id: carInfo.id,
                make: carInfo.make,
                model: carInfo.model,
                year: `${carInfo.year}`,
                colour: carInfo.colour,
                license_plate: carInfo.registrationPlate,
                vin: carInfo.vin,
                body_type: carInfo.bodyType,
                odometer: carInfo.odometer,
            }
            : noVehicleDetails
    );

    const [loading, error, errMessage, vehicles] = useServiceHook(
        VehicleService.getVehicles, [{ vehicle_type: plan.value, hub: hub.value }, Country[country]],
        allowServiceRun,
        [plan.value, carInfo.hub]  // <= dependency for the hook 
    );

    const onSelectPlan = (event: ChangeEvent<HTMLSelectElement>) => {
        // When plan is selected, store it into the state and to the context.
        // The plan state value is a dependency of the service hook, triggering a
        // server call.
        setPlan(planOptions[country].filter(item => item.value === event.target.value)[0]);
        updateFormState(UserFormStates.driverDetails, { plan: event.target.value });

        // Each time a new plan is selected, 
        // available vehicles for it will be retrieved,
        // meaning that the user has to pick one again.
        // Also have to reset context for everything but state
        setSelectedVehDetails(noVehicleDetails);
        updateFormState(UserFormStates.carInfo, {
            id: noVehicleDetails.id, make: noVehicleDetails.make, model: noVehicleDetails.model,
            year: parseInt(noVehicleDetails.year), colour: noVehicleDetails.colour, registrationPlate: noVehicleDetails.license_plate,
            vin: noVehicleDetails.vin, bodyType: noVehicleDetails.body_type, odometer: noVehicleDetails.odometer
        })
    };

    const onSelectHub = (event: ChangeEvent<HTMLSelectElement>) => {
        // Each time a new hub is selected, available vehicles
        // for it will be retrieved, meaning that the user has
        // to pick one again.
        // The reset is done first because the carInfo context
        // is used bellow for a set operation (so the reset would've
        // overridden the set one).
        setSelectedVehDetails(noVehicleDetails);
        resetFormState(UserFormStates.carInfo);

        // When a hub is selected, store it into the state and to the context.
        // The hub state value is a dependency of the service hook, triggering a
        // server call.
        setHub(hubs[Country[country]].filter(item => item.value === event.target.value)[0]);
        updateFormState(UserFormStates.carInfo, { hub: event.target.value });
    };

    const onSelectVeh = (event: ChangeEvent<HTMLSelectElement>) => {
        // When a car is selected on the drop down menu
        // 1. update the car details state so they will be displayed
        // to the user
        // 2. update the context, so veh details will be used later
        // 3. display the odometer value
        const vehicleId = event.currentTarget.value;
        const carDetails: IVehicle = vehicles.filter((item: IVehicle) => item.id === vehicleId)[0];
        if (!carDetails) {
            setSelectedVehDetails(noVehicleDetails);
            resetFormState(UserFormStates.carInfo);
            setShowOdometer(false)
            return;
        }
        setSelectedVehDetails(carDetails);
        setStartingOdometer(carDetails.odometer)
        updateFormState(UserFormStates.carInfo, {
            id: carDetails.id,
            make: carDetails.make,
            model: carDetails.model,
            year: Number(carDetails.year),
            colour: carDetails.colour,
            registrationPlate: carDetails.license_plate,
            vin: carDetails.vin,
            bodyType: carDetails.body_type,
            odometer: carDetails.odometer,
        });
        setShowOdometer(true)
    };

    // Allows us to search for events relating to the logged in user details and plan
    Sentry.setTag("CSR", contractDetails.contactOwner)
    Sentry.setTag("plan", driverDetails.plan)
    Sentry.setTag("hub", carInfo.hub)
    Sentry.setTag("customer name", personalInfo.firstName + ' ' + personalInfo.lastName)
    Sentry.setTag("customer email", personalInfo.email)
    Sentry.setTag("crmid", driverDetails.crmId)
    Sentry.setTag("Start Time", new Date().toString() )
    
    // This keeps the breadcrumb object up to date as the user enters details
    useEffect(() => {
        breadcrumbObject['Vehicle assignment']['Selected Plan'] = driverDetails.plan;
        Sentry.setTag("plan", driverDetails.plan)
        breadcrumbObject['Vehicle assignment']['Hub'] = carInfo.hub;
        Sentry.setTag("hub", carInfo.hub)
        breadcrumbObject['Vehicle assignment']['Odometer'] = carInfo.odometer.toString();
    }, [driverDetails.plan, carInfo.hub, carInfo.odometer])
    
    // Check if user has been redirected from another site (e.g. Zoho Sign) by evaluating a value in the context.
    // If not all the information is present then redirect the user to the homescreen
    useEffect(() => {
        // The customer started journey breadcrumb object is in a seperate useEffect because it is to be
        // executed on first page render and not updated with user input as the rest of the breadcrumb object is.
        breadcrumbObject['Vehicle assignment']['Customer Started Journey'] = new Date();
        if (contractDetails.contactOwnerEmail === ""){
            window.location.href = '/'
        }
    })

    useEffect(() => {
        carInfo.odometer < startingOdometer ? setOdometerCheck(true) : setOdometerCheck(false)
    }, [carInfo.odometer])

    return (<>
        <NavBar pageTitle={"Vehicle assignment"} backAction={undefined} />
        <form style={{ margin: '2.5vh 2.5vw' }} onSubmit={() => navigate(`/onboarding/details`)} >
            <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw' }}>
                <Label
                    text={'Please select the plan the customer is onboarding on:'}
                    styleCfg={{ largeFont: false, bold: false }}
                />
                <DropDownMenu
                    menuName={'plan'}
                    defaultVal={plan}
                    required={true}
                    choices={planOptions[country]}
                    onSelect={(event: ChangeEvent<HTMLSelectElement>) => onSelectPlan(event)}
                    testId={'plan-dropdown'}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw' }}>
                <Label
                    text={`Please select the ${hubOrState} the customer is onboarding on:`}
                    styleCfg={{ largeFont: false, bold: false }}
                />
                <DropDownMenu
                    menuName={'hub'}
                    defaultVal={hub}
                    required={true}
                    choices={hubs[Country[country]]}
                    onSelect={onSelectHub}
                    testId={'hub-dropdown'}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '2.5vh 0vw' }}>
                <Label
                    text={'Please assign a vehicle to the member:'}
                    styleCfg={{ largeFont: false, bold: false }}
                />
                {loading === false || loading === null  // not null and not false means at least 1 req was made
                    ? <DropDownMenu
                        menuName={'vehicle'}
                        required={true}
                        testId={'vehicle-dropdown'}
                        defaultVal={carInfo.make && vehicles
                            ? {
                                label: selectedVehDetails.license_plate,
                                value: selectedVehDetails.id,
                                enabled: true
                            }
                            : undefined
                        }
                        choices={vehicles
                            ? vehicles.map((item: IVehicle) => ({
                                label: item.license_plate + ' - ' + item.make + ' ' + item.model,
                                value: item.id,
                                enabled: true
                            }))
                            : []
                        }
                        // key={vehicles.value}
                        onSelect={(event: ChangeEvent<HTMLSelectElement>) => onSelectVeh(event)}
                    />
                    : <div style={{ height: '5vh' }}><Elliptical /></div>
                }
            </div>
            <p style={{ margin: '2.5vh 0' }}>
                If a vehicle is not available for assignment in the dropdown please raise with your Ops Lead.
            </p>
            <VehicleDetails
                make={selectedVehDetails.make}
                model={selectedVehDetails.model}
                year={selectedVehDetails.year}
                color={selectedVehDetails.colour}
                licensePlate={selectedVehDetails.license_plate}
                vin={selectedVehDetails.vin}
                bodyType={selectedVehDetails.body_type}
                startingMiles={selectedVehDetails.odometer}
            />
            <div style={{ margin: '2.5vh 0' }}>
                <p>Starting {countrySpecificDistanceUnit(country)}</p>
                <input
                    style={{
                        borderRadius: '10px',
                        border: '1px solid #C4C4C4',
                        height: '5vh',
                        textAlign: 'left',
                        width: '50%',
                    }}
                    type="text"
                    name='odometer'
                    value={showOdometer ? selectedVehDetails.odometer : ""}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const inputVal = e.target.value;
                        const numericVal = inputVal.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                        setSelectedVehDetails(prevObj => ({ ...prevObj, odometer: Number(numericVal) }));
                        updateFormState(UserFormStates.carInfo, { odometer: Number(numericVal) });
                    }}
                />

            </div>
            <p style={{ margin: '2.5vh 0' }}>Please double check the odometer in the vehicle as part of the onboarding process.</p>
            

            <div style={{ margin: '2.5vh 0' }}>
                {odometerCheck && <WarningBox message={"The odometer value provided is less than the recorded value in Fleetio. You can still proceed with the assignment; however, you will need to manually update the value in both Geotab and Fleetio after the onboard"} />}
            </div>

            <SubmitBtn text={"Next"} breadcrumbObject={breadcrumbObject} componentName={componentName}/>
        </form>
    </>
    );
};