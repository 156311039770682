import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { EnvTag, getEnvTag, prodHostname, devHostname } from '@utils/AppEnv';

// Set redirect URL based on app environment.
// IMPORTANT: All supported URLs must be defined in MS Azure for the
// authentication to work correctly.
let redirectForEnv = '';
switch (getEnvTag()) {
    case EnvTag.Production:
        redirectForEnv = `https://${prodHostname}`;
        break;
    case EnvTag.Development:
        redirectForEnv = `https://${devHostname}`;
        break;
    case EnvTag.Local:
        redirectForEnv = 'https://localhost:3000';
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "1da4a0d8-7e9c-42e2-8565-a90636b52700",
        authority: "https://login.microsoftonline.com/ed97c509-c38d-4c4c-bfbc-8871ed133cec",
        redirectUri: redirectForEnv,
        postLogoutRedirectUri: "/",
    },
    system: {
        tokenRenewalOffsetSeconds: 2100 // 35 minutes before expiry. Used by acquireTokenSilent
                                        // (in MSAuthAPI.getMSIdToken) as the Access Token's expiration can be up to
                                        // 30 minutes later than the ID Token's expiration
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
// Currently unused, might come in handy
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
