import React, { CSSProperties, useEffect } from 'react';

import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { PanelItem } from '@components/panels/PanelItems';

import { countrySpecificCurrency } from '@utils/misc/functions';


export default function IgnitionView() {

    const { formState, updateFormState } = useUserJourneyContext();
    const { driverDetails, contractDetails, carInfo } = formState;

    const divStyles: CSSProperties = {
        margin: '2.5vh 0vw',
    };

    const addWeeks = (dateString: string, weeks: number): string => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date string');
        }
        date.setDate(date.getDate() + weeks * 7);
        return date.toISOString().split('T')[0];
    };

    const endDate = addWeeks(contractDetails.subsStartDate, 12);
    useEffect(() => {
        updateFormState(UserFormStates.splendInitiatives, { ignitionEndDate: endDate });
    }, [])

    return (<>
        <div style={divStyles}>
            Splend’s “Ignition” programme is a short term, weekly discount of £50 per week, for a total of 12 weeks for eligible new to Uber customers.
        </div>

        <div style={divStyles}>
            This declaration is required to receive the “EV Ignition” discount. The Ignition discount will be equal to £50.00 and apply to each weekly subscription,
            for a total of 12 weeks, after which the discount will expire.
        </div>

        <div style={divStyles}>
            <PanelItem
                header='Subscription start date' headerTextStyleCfg={{ bold: true }}
                value={contractDetails.subsStartDate || ''} />
            <PanelItem
                header={`Standard Subscription Price (per wk, inc VAT)(${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planWeeklyFee || ''} />
            <PanelItem
                header={`Ignition discount (${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={"50"} />
            <PanelItem
                header={`Date Ignition discount expires (${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={endDate || ''} />
        </div>
    </>);

};

