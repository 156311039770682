import React, { useState, useRef, useCallback, CSSProperties } from 'react';
import Switch from 'react-switch';
import useCustomerSearch, { ICustomer } from '@components/inputs/CustomerSearchBar/CustomerSearchHook';
import CustomerSummaryCard from '@components/cards/CustomerSummary/CustomerSummary';
import Button from '@components/buttons/Button/Button';
import LoadingSpinner from '@components/spinners/Circular/Circular';
import searchIcon from '@assets/images/white_search_icon.png';
import arrowRight from '@assets/images/arrow_right.svg';

export default () => {

    const custSearchBarPlaceholder: string = 'Search by name, email or Zoho Contact ID';
    const custSearchResPerPage: number = 15;

    // UK - false, AU - true
    const defaultCountry: boolean = false;

    // mapping to link toggle button state to a country
    const toggleBtnStateMapping: { [boolStr: string]: string } = {
        'true': 'GB',
        'false': 'AU',
    };

    const [searchTerm, setSearchTerm] = useState<string>('');  // searchTerm is the text that is actually sent on the request to the server
    const [typing, setTyping] = useState<string>('');  // stores the real time typing value; it's used as a auxiliary var alongside searchTerm to improve the UX
    const [offset, setOffset] = useState<number>(0);  // used on pagination; stores the no. of the last retrieved record so the next request will get data starting from the last retrieved one + 1
    const [toggleBtnState, setToggleBtnState] = useState<boolean>(defaultCountry);  // required by the toggle button
    const [country, setCountry] = useState<string>(toggleBtnStateMapping[String(defaultCountry)]);  // string-country representation of the toggle button state

    const { loading, error, errMessage, customers, hasMore } = useCustomerSearch(searchTerm, country, offset, custSearchResPerPage);

    const onSearch = () => {
        // Each new search consists of setting the search term
        // with the text that is at that moment in the input text bar.
        // Any update on the overall search criteria will reset the
        // offset. It gets updated automatically when the last result item
        // appears on the screen (as a result of scrolling) and a new
        // request is automatically triggered in the background
        setSearchTerm(typing);
    };

    const handleToggleChange = (state: boolean) => {
        // Each toggle change presumably represents an intention for a new search,
        // so set the toggle btn state and country code accordingly. Also reset the search term,
        // but not the typed text, so the empty search term will trigger a deletion of the
        // previous results (via the hook) to have a clean new search. Do be aware that the
        // typed text value will still be available and used to set the search term in order
        // to avoid making the user to type the same thing again, which would've happened if the
        // typed text would've also been reset (this being the main reason of having a typed
        // text value and a search term value).
        setToggleBtnState(state);
        setCountry(toggleBtnStateMapping[String(state)]);
        setSearchTerm('');
        setOffset(0);
    };

    const handleInputChange = (event: any) => {
        // Every typing on the input bar presumes to be the intention of a new search
        // so the typing state is updated in real time and the search term and pagination
        // offset are reset as preparation of a clean new search
        setTyping(event.target.value);
        setSearchTerm('');
        setOffset(0);
    };

    // Infinite scrolling functionality.
    // Checking if last result item appeared on the screen.
    // If yes, then update the offset, action which triggers
    // the request for the next page
    const observer = useRef<IntersectionObserver | null>(null);
    const lastResultRef = useCallback((node: any) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setOffset(prevOffset => prevOffset + custSearchResPerPage);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const Customers = ({ customers }: { customers: ICustomer[] }) => (<>{
        customers.map((cust: ICustomer, index: number) => (
            // Each item would represent a clickable card and styled accordingly, with the small arrow on the right.
            <div style={{ display: 'flex', borderTop: '1px solid #E1E1E1', cursor: 'pointer', padding: '1vh 2vw' }}>
                <CustomerSummaryCard key={cust.id}
                    ref={index === customers.length - 1 ? lastResultRef : undefined}
                    custName={`${cust.First_Name} ${cust.Last_Name}`}
                    custId={cust.id}
                    email={cust.Email}
                    country={country}
                />
                <div style={{alignSelf: 'center', marginLeft: '2vw'}}><img src={arrowRight} /></div>
            </div>
        ))}
    </>);

    const noErrorAfterSearch = customers.length === 0 && searchTerm !== '' && loading === false && error !== true;

    const searchboxLayout: CSSProperties = {
        // Transparent input box, also on focus, because the boarding is done in the containing div.
        textIndent: '2vw',
        height: '58px',
        border: 'transparent',
        outline: 'none',
        backgroundColor: 'transparent',
        flexGrow: 1
    };

    // When loading, set bigger opacity to hint a disabled item.
    const opacity: CSSProperties = { opacity: loading !== true ? 1 : 0.3 };

    return (<>
        {/* search+results section framed with an expanding-as-needed bordered div */}
        <div style={{ display: 'flex', boxSizing: 'border-box', borderRadius: '10px', border: '1px solid #C4C4C4', marginBottom: '2vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <div style={{ display: 'flex' }}>
                    {/* Having the text input, country toggle and search btn on same visual line as they were all from the same input item. */}
                    <input
                        className='customer-search-bar-input'
                        data-testid='customer-search-bar'
                        disabled={loading}
                        style={{ ...searchboxLayout, ...opacity }}
                        placeholder={custSearchBarPlaceholder}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Prevent form submission (if inside a form)
                                onSearch();
                            }
                        }}
                    />
                    <div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', marginLeft: '0 1vw' }}>
                        <span style={{ marginRight: '5px', ...opacity }}>AU</span>
                        <Switch
                            disabled={loading}
                            onChange={handleToggleChange}
                            checked={toggleBtnState}
                            offColor='#dfdfdf' onColor='#dfdfdf'
                            offHandleColor='#6F217B' onHandleColor='#6F217B'
                            handleDiameter={30} height={20} width={48}
                            uncheckedIcon={false} checkedIcon={false}  // Not displaying checked/unchecked icons within the switch btn.
                            boxShadow='0px 1px 5px #5e1c69'
                            activeBoxShadow='0px 0px 1px 10px #5e1c69'
                        />
                        <span style={{ marginLeft: '5px', ...opacity }}>UK</span>
                    </div>
                    <div style={{ alignSelf: 'center', margin: '0 1vw', ...opacity }}>
                        <Button label={'Search'} iconSrc={searchIcon} onClickFunc={onSearch} disabled={loading} />
                    </div>
                </div>
                <Customers customers={customers} />
                {noErrorAfterSearch
                    ? <NoResults />
                    : hasMore === false && <EndOfResults />
                }
            </div>
        </div>
        {loading && <Spinner />}
        {error && <ErrMsg text={errMessage} />}
    </>);
};


const Spinner = () => <div style={{ textAlign: 'center', margin: '1vh 0vh' }}><LoadingSpinner /></div>;
const NoResults = () => <p style={{ textAlign: 'center', margin: '1vh 0vh', borderTop: '1px solid #E1E1E1', }}>No results</p>;
const EndOfResults = () => <p style={{ textAlign: 'center', margin: '1vh 0vh', borderTop: '1px solid #E1E1E1', }}>End of results</p>;
const ErrMsg = ({ text }: { text: string }) => <p style={{ textAlign: 'center', margin: '1vh 0vh' }}>{text}</p>;
