import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';

import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { PanelItem } from '@components/panels/PanelItems';
import { IPlan, PlanService } from '@services/SubscriptionPlans';
import { dateToISOFormat } from '@utils/misc/functions';
import Label from '@components/inputs/text/Label';
import DatePicker from '@components/inputs/DateInput';
import DropDownMenu from '@components/inputs/DropDownMenu';
import useServiceHook from '@hooks/useServiceHook';
import Elliptical from '@components/spinners/Elliptical/Elliptical';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';


export default function RAVAgreementSchedule({ breadcrumbObject }: BreadcrumbProps) {
    const todaysDate = dateToISOFormat(new Date());  // YYYY-mm-dd

    const { formState, updateFormState } = useUserJourneyContext();
    const { driverDetails, contractDetails, carInfo } = formState;
    const [executionDate, setExecutionDate] = useState<string>(contractDetails.executionDate || todaysDate);
    const [subsStartDate, setSubsStartDate] = useState<string>(contractDetails.subsStartDate || todaysDate);
    const [showBlank, setShowBlank] = useState<boolean>(true);  // show blank value before a plan is selected

    // If a plan is found in the context, set it as default, being the user's last selection
    let defaultPlan = contractDetails.planId
        ? { label: contractDetails.planName, value: contractDetails.planId, enabled: true }
        : undefined;

    const [loading, , , availablePlans] = useServiceHook(
        PlanService.getPlans,
        [{ plan_type: driverDetails.plan, state: carInfo.hub, term: "N/A" }, driverDetails.country],  // carInfo.hub plays the role of state
    );

    useEffect(() => { // runs only at first page render
        // if no executionDate, take it from state, which should've been set to today
        contractDetails.executionDate === '' &&
            updateFormState(UserFormStates.contractDetails, { executionDate });

        // if no subsStartDate, take it from state, which should've been set to today
        contractDetails.subsStartDate === '' &&
            updateFormState(UserFormStates.contractDetails, { subsStartDate });
    }, []);

    useEffect(  // update context's subsStartDate field upon state update
        () => updateFormState(UserFormStates.contractDetails, { subsStartDate }),
        [subsStartDate]
    );

    useEffect(  // update context's executionDate field upon state update
        () => updateFormState(UserFormStates.contractDetails, { executionDate }),
        [executionDate]
    );

    const onPlanChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedPlan = availablePlans.filter((item: IPlan) => item.id === e.target.value)[0];
        updateFormState(
            UserFormStates.contractDetails,
            {
                planId: selectedPlan.id,
                planName: selectedPlan.name,
                planWeeklyFee: selectedPlan.weeklyHireFee,
                // planSetupFee can be reset via state update below
                planMinimumPeriod: selectedPlan.minimumPeriodWeeks,
                planWeeklyDistAllowance: selectedPlan.weeklyDistAllowance,
                planAdditionalDistCharge: selectedPlan.additionalDistCostPerUnit,
                planDailyFee: parseFloat((selectedPlan.weeklyHireFee / 7).toFixed(2)),
                planMinimumDailyPeriod: selectedPlan.minimumPeriodWeeks * 7
            }
        );
        setShowBlank(false);
    };

    const divStyles: CSSProperties = {
        margin: '2.5vh 0vw',
    };

    useEffect(() => {
        breadcrumbObject['Agreement schedule']['Execution Date'] = contractDetails.executionDate
        breadcrumbObject['Agreement schedule']['Subscription Start Date'] = contractDetails.subsStartDate
        breadcrumbObject['Agreement schedule']['Subscription Start Date'] = contractDetails.subsStartDate
    }, [contractDetails]);

    return (<>
        <p style={{ margin: '2.5vh 0' }}>Please ensure the key terms for the customer are correct.</p>
        <div style={divStyles}>
            <Label text='Contract execution date' requiredFieldInd={true} />
            <DatePicker
                name='contractExeDate'
                defaultVal={executionDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setExecutionDate(e.currentTarget.value)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Subscription start date' requiredFieldInd={true} />
            <DatePicker
                name='contractStartDate'
                defaultVal={subsStartDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setSubsStartDate(e.currentTarget.value)}
            />
        </div>
        <div style={divStyles} >
            <Label text='Plan' />
            {loading === false || loading === null
                ? <DropDownMenu
                    menuName='planDropMenu'
                    defaultVal={defaultPlan}
                    required={true}
                    choices={availablePlans
                        ? availablePlans.map((item: any) => ({ label: item.name, value: item.id, enabled: true }))
                        : []
                    }
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => onPlanChange(e)}
                />
                : <div style={{ height: '5vh' }}><Elliptical /></div>
            }
        </div>
        <div style={{ margin: '2.5vh 0vw' }}>
            <PanelItem
                header='Minimum period (days)' headerTextStyleCfg={{ bold: true }}
                value={showBlank === true ? '' : contractDetails.planMinimumDailyPeriod} />
            <PanelItem
                header={driverDetails.plan == "RAV Short Term Rental" ? 'Weekly hire fee ($)' : 'Daily hire fee ($)'} headerTextStyleCfg={{ bold: true }}
                value={driverDetails.plan == "RAV Short Term Rental" ? contractDetails.planWeeklyFee || '' : contractDetails.planDailyFee || ''} />
            <PanelItem
                header='Weekly distance allowance (km)' headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planWeeklyDistAllowance || ''} />
            <PanelItem
                header='Additional km charge ($)' headerTextStyleCfg={{ bold: true }}
                value={showBlank === true ? '' : contractDetails.planAdditionalDistCharge} />
        </div>
    </>);
};
