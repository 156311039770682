import Label from '@components/inputs/text/Label';
import React, {CSSProperties, useEffect, useState} from 'react';
import {UserFormStates, useUserJourneyContext} from '@context/UserJourneyContext';
import cancellationReasons from "../../SmartOpsHome/cancellationReasons";
import {useNavigate, useParams} from 'react-router';
import DatePicker from '@components/inputs/DateInput';

// Enum definition in TypeScript
enum CompletionStatus {
    Category = "Category",
    Subcategory = "Subcategory",
    Type = "Type",
  }

enum VehicleTransferType {
    FlexiOwnCompletion = "Flexi Own Completion"
}

interface CancellationReasonOption {
        Type?: string;
        Categories?: CancellationReasonCategory[];
    }
    interface CancellationReasonCategory {
        Category?: string;
        Subcategories?: CancellationReasonSubcategory[];
    }
    interface CancellationReasonSubcategory {
        Subcategory?: string;
        ReturnDateField?: boolean;
        AddRestrictionTagField?: boolean;
    }
    type CompletionStatusType = keyof typeof CompletionStatus;

// create data structure using values stored in state pass into generate next dropdown
export const generateNextDropdownData = (
    level: CompletionStatusType,
    dataStructure: Record<CompletionStatusType, string>,
    result: CancellationReasonOption[]
): CancellationReasonCategory[] | CancellationReasonSubcategory[] => {
    // Logic to generate the next dropdown data based on the selected values
    switch (level) {
        case CompletionStatus.Type:
            return result.find((item) => item.Type === dataStructure[level])?.Categories || [];
        case CompletionStatus.Category:
            return (
                result
                .find((item) => item.Type === dataStructure[CompletionStatus.Type])
                ?.Categories?.find((category) => category.Category === dataStructure[level])
                ?.Subcategories || []
            );
      default:
          return [];
    }
};

const result = cancellationReasons

/**
 * CancellationReason is a React component that creates a series of dropdowns that render based on the selected
 * options in the previous dropdown using values stored in state when selecting options.
 *
 * @component
 * @example
 * // Usage in another component or file:
 * import MyComponent from './MyComponent';
 *
 * const App = () => {
 *   return (
 *     <div>
 *       <CancellationReason />
 *     </div>
 *   );
 * };
 *
 * @param No params
 * @returns {JSX.Element} The rendered CancellationReason component.
 */

export default function CancellationReason() {
    const [selectedType, setSelectedType] = useState<string>('');
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [selectedSubcategory, setSelectedSubcategory] = useState<string>('');

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    const { updateFormState} = useUserJourneyContext();

    const handleMainDropdownChange = (level: CompletionStatusType, value: string) => {
        // Update the selected value based on the dropdown level
        switch (level) {
            case CompletionStatus.Type:
                setSelectedType(value);
                updateFormState(UserFormStates.cancellationDetails, {cancellationType: value, cancellationCategory: '', cancellationSubCategory: ''});
                setSelectedCategory('');
                setSelectedSubcategory('');
                break;
            case CompletionStatus.Category:
                setSelectedCategory(value);
                updateFormState(UserFormStates.cancellationDetails, {cancellationCategory: value, cancellationSubCategory: ''});
                setSelectedSubcategory('');
                break;
            case CompletionStatus.Subcategory:
                setSelectedSubcategory(value);
                updateFormState(UserFormStates.cancellationDetails, {cancellationSubCategory: value});
                break;
            default:
                break;
        }
    };

    const allCancellationReasons: Record<CompletionStatus, string> = {
        [CompletionStatus.Type]: selectedType,
        [CompletionStatus.Category]: selectedCategory,
        [CompletionStatus.Subcategory]: selectedSubcategory,
    };

    const dropDownListStyles: CSSProperties = {
        height: '5vh',
        border: '1px solid #C4C4C4',
        borderRadius: '10px',
        minWidth: '100%',
    };

    const get_dropdown_title_for_level = (level: string) => {
        switch (level) {
            case CompletionStatus.Type:
                return 'Cancellation type';
            case CompletionStatus.Category:
                return 'Cancellation reason - outcome';
            case CompletionStatus.Subcategory:
                return 'Cancellation reason - reason';
            default:
                return 'Select';
        }
    }

    const renderDropdown = (level: CompletionStatusType, options: any) => (
        options && options.length > 0 && (
            <div key={level}>
                <Label text={get_dropdown_title_for_level(level)} requiredFieldInd={true} />
                <select
                    style={dropDownListStyles}
                    name={'renderedDropdown'}
                    data-testid={'drop-down-menu-testid-' + level}
                    onChange={(e) => handleMainDropdownChange(level, e.target.value)}
                    required={true}
                >

                    <option value="">{`- please select -`}</option>
                    {options.map((item: any) => (
                        <option key={item[level]} 
                            value={item[level]}
                            disabled={level === CompletionStatus.Category && item[level] === VehicleTransferType.FlexiOwnCompletion}>
                            {item[level]}
                        </option>
                    ))}
                </select>
                {/*If the level is subcategory, check if any extra fields needed to be rendered*/}
                {level === 'Subcategory'
                    && allCancellationReasons["Subcategory"]
                    && options.some((option: {
                        Subcategory: any;
                        ReturnDateField: any;
                        AddRestrictionTagField: any;
                    }) => option.Subcategory === allCancellationReasons[CompletionStatus.Subcategory]) && (
                        <div style={divStyles}>

                            {/*Return date field*/}
                            {options.find((option: {
                                    Subcategory: any;
                                    ReturnDateField: any;
                                }) => option.Subcategory === allCancellationReasons[CompletionStatus.Subcategory]).ReturnDateField === true &&
                                <>
                                    <Label text={'Return Date'}/>
                                    <DatePicker
                                        name='contractEndDate'
                                        data-testid="contractEndDate"
                                        required={true}
                                        onChange={onReturnDateChange}
                                    />
                                </>
                            }

                            {/*Add restriction tag field*/}
                            {options.find((option: {
                                    Subcategory: any;
                                    AddRestrictionTagField: any;
                                }) => option.Subcategory === allCancellationReasons[CompletionStatus.Subcategory]).AddRestrictionTagField === true &&
                                <>
                                    <Label text={'Add restriction tag to account?'}/>
                                    <select
                                        style={dropDownListStyles}
                                        name={'restrictionTag'}
                                        data-testid={'restriction-tag-testid'}
                                        onChange={handleRestrictionTagChange}
                                    >
                                        {dropDownRestrictionTagOptions.map((item: any) => (
                                            <option key={item.value} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            }

                        </div>
                    )}
            </div>
        )
    );

    const checkIsButtonDisabled = () => {
        if (selectedCategory !== '' && selectedType !== '') {
            return false
        } else if (selectedCategory !== '') {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        setIsButtonDisabled(checkIsButtonDisabled)
    }, [selectedCategory, selectedType])

    const handleAdditionalInfoComments = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.cancellationDetails, {cancellationAdditionalInfo: e.target.value});
    };


    const onReturnDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateFormState(UserFormStates.cancellationDetails, {customerReturnDate: e.target.value});
    }

    const dropDownRestrictionTagOptions = [
        {label: 'Yes', value: 'Yes'},
        {label: 'No', value: 'No'}
    ];
    const [restrictionTag, setRestrictionTag] = useState<string>("");
    const handleRestrictionTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRestrictionTag(e.target.value);
        updateFormState(UserFormStates.cancellationDetails, {cancellationAddRestrictionToAccount: e.target.value});
    };

    useEffect(() => {
        if (selectedType === 'Involuntary (Splend Choice)') {
            setRestrictionTag('Yes');
            updateFormState(UserFormStates.cancellationDetails, {cancellationAddRestrictionToAccount: 'Yes'});
        } else {
            setRestrictionTag('');
            updateFormState(UserFormStates.cancellationDetails, {cancellationAddRestrictionToAccount: ''});
        }
    }, [selectedSubcategory]);

    const divStyles: CSSProperties = {
        margin: '2.5vh 0vw',
    };

    const buttonContainerStyle = {
        marginTop: '5vh',
    };

    return (<>
        <div style={divStyles}>
            <p>Please record a <strong>reason for the cancellation</strong>, providing as much detail as possible.</p>
        </div>
        <div style={divStyles}>
            {/*Render type dropdown*/}
            {renderDropdown('Type', result)}
        </div>

        <div style={divStyles}>
            {/*Render category dropdown*/}
            {selectedType &&
                renderDropdown(
                    CompletionStatus.Category,
                    generateNextDropdownData(CompletionStatus.Type, allCancellationReasons, result)
                )}
        </div>
        <div style={divStyles}>
            {/*Render subcategory dropdown and additional fields if required*/}
            {selectedCategory &&
                renderDropdown(
                    CompletionStatus.Subcategory,
                    generateNextDropdownData(CompletionStatus.Category, allCancellationReasons, result)
                )}
        </div>

        {/*Additional comments field is present for all types / categories*/}
        <div style={divStyles}>
            <Label text={'Additional Comments'}/>
            <input
                style={dropDownListStyles}
                name={'additionalComments'}
                data-testid={'additional-comments-testid'}
                onChange={handleAdditionalInfoComments}
            />
        </div>
    </>);
};
