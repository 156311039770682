import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { NavBar } from "@components/navigation/NavBar/NavBar";
import useMultiStepForm from "@hooks/useMultiStepForm";
import SubmitBtn from "@components/buttons/FormButtons/SubmitBtn";
import { useUserJourneyContext, UserFormStates } from "@context/UserJourneyContext";
import { Country } from "@utils/constants/localisation";
import { useAPIError } from "@context/ServerErrorContext";
import Warning from "@components/modal/info/Warning";
import ModalContainer from '@components/modal/Container';
import CircularSpinner from "@components/spinners/Circular/Circular";
import { addBeforeUnloadListener, removeBeforeUnloadListener } from "@utils/refreshCatcher";
import CancellationVehicleDetails from "../Cancellations/CancellationVehicleDetails";
import CancellationPersonalDetails from "../Cancellations/CancellationPersonalDetails";
import CancellationAddressDetails from "../Cancellations/CancellationAddressDetails";
import CancellationReason from "../Cancellations/CancellationReason";
import CancellationCharges from "../Cancellations/CancellationCharges";
import CancellationReview from "../Cancellations/CancellationReview";
import CancellationChargesUK from "../Cancellations/CancellationChargesUK";
import SubscriptionService from '@services/subscription/SubscriptionService';


/**
 * Component that puts together the steps for all cancellation
 * journeys into what is a multi step form.
 * 
 * Each step is a component that simply exposes some inputs.
 * There is a context that's shared across the entire journey,
 * meant to provide default values to the input fields (where
 * it is possible and justified). If any are updated, then the
 * new value will be stored into the context.
 * 
 * When the user reaches the last step (having all mandatory fields
 * filled) and submits the form, the frontend will take the info 
 * from the context, send it to the backend make the vendor updates
 * then redirect to the cancellation confirmation page.
 * 
 * If there will be any errors during the server calls, the error
 * context (that wraps all routes/pages) state will be set, making
 * a modal to be shown. The only option the user will have is an Ok
 * button, which once clicked, the user will be redirected to the
 * homepage, loosing all progress.
 */
export default function CancellationForm() {

    const { formState: {
        carInfo,
        addressInfo,
        contractDetails,
        driverDetails,
        personalInfo,
        cancellationDetails,
    } } = useUserJourneyContext();

    const { updateFormState } = useUserJourneyContext();

    const [loading, setLoading] = useState<Boolean>(false);
    const [stepNames, setStepNames] = useState<string[] | string>('');
    const [steps, setSteps] = useState<any>('');

    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { setErr, removeErr } = useAPIError();
    const navigate = useNavigate();

    const newCountry = driverDetails.country

    const cancellationStepsAU: React.ReactElement[] = [
        <CancellationVehicleDetails />,
        <CancellationPersonalDetails />,
        <CancellationAddressDetails />,
        <CancellationReason />,
        <CancellationCharges />,
        <CancellationReview />,
    ];

    const cancellationStepsUK: React.ReactElement[] = [
        <CancellationVehicleDetails />,
        <CancellationPersonalDetails />,
        <CancellationAddressDetails />,
        <CancellationReason />,
        <CancellationChargesUK />,
        <CancellationReview />,
    ];
    
    // The names of each component to populate NavBar for RAV journey
    const cancellationStepNames: string[] = [
        'Vehicle Details',
        'Personal details',
        'Address details',
        'Cancellation reason',
        'Cancellation charges',
        'Review',
    ];

    useEffect(() => {
        setSteps(newCountry === Country.GB ? cancellationStepsUK : cancellationStepsAU);
        setStepNames(cancellationStepNames);
    }, [newCountry]);

    // The only purpose of this hook is to check whether a value that is required to progress through the cancellation form is present.
    // Country is being used as a proxy to see if the user is able to progress with the form, the value or type of country can change
    // and this will remain functional but there has to be a value for country present for this hook to work. If a page is refreshed then
    // the information in the context will be wiped, this hook will then see that change and redirect the user back to the homepage to
    // restart the journey.
    useEffect(() => {
        if (!driverDetails.country) {
            window.location.href = "/";
        }
    }, []);

    useEffect(() => {
        addBeforeUnloadListener(); // add event listener when starting the form
        return () => {
            removeBeforeUnloadListener(); // end event listener at the end of the form
        };
    }, []);

    // Checks email pulled from Zoho for null or invalid entries. Email length <= 4 has been
    // left in on purpose as a quick check to fix production issue but will need to be 
    // expanded on in the future
    const checkValidEmailAddress = () => personalInfo.email && personalInfo.email.length >= 4;

    const {
        currentStepIndex,
        step,
        isFirstStep,
        isLastStep,
        back,
        next
    } = useMultiStepForm(steps);

    const onSubmitForm = async (event: FormEvent) => {
        removeBeforeUnloadListener(); // end event listener at the end of the form

        event.preventDefault();

        // if the email address fails validation then display error and redirect to homepage
        if (!checkValidEmailAddress()) {
            setErr(
                true,
                {
                    msg: 'Please enter a valid email address on Zoho and try again',
                    UI: <InvalidEmailWarningModal onClickClose={() => { removeErr(); navigate('/'); }} />
                }
            );
        }

        // if it's not the last step, then go to the next one
        if (isLastStep == false) { next(); return; }

        setLoading(true);

        const payload = {
            contact_id: cancellationDetails.contactId,
            plan: cancellationDetails.cancellationPlanType,

            cancellation_type: cancellationDetails.cancellationType,
            cancellation_category: cancellationDetails.cancellationCategory,
            cancellation_sub_category: cancellationDetails.cancellationSubCategory,

            customer_returning_date: cancellationDetails.customerReturnDate,
            cancellation_add_restriction_to_account: cancellationDetails.cancellationAddRestrictionToAccount,

            cancellation_additional_info: cancellationDetails.cancellationAdditionalInfo,

            cancellation_date: cancellationDetails.cancellationDate,

            first_name: personalInfo.firstName,
            middle_name: personalInfo.middleName,
            last_name: personalInfo.lastName,
            email: personalInfo.email,
            street: addressInfo.street,
            city: addressInfo.city,
            province: addressInfo.state,
            postcode: addressInfo.postcode,

            vehicle_id: carInfo.vehicleId,
            fleetio_status_id: cancellationDetails.fleetioStatusId,
            odometer: cancellationDetails.endingKms,

            addons: cancellationDetails.addons,
            notice_charges: cancellationDetails.noticePeriodCharge,
            term_charges: cancellationDetails.finalMinimumTermCharges,
            acceptance_and_remarketing_fee: cancellationDetails.acceptanceAndRemarketing,
            registration: carInfo.registrationPlate,
            total_ancillary_charges: cancellationDetails.totalAncillaryCharges,
            vehicle_depreciation_fee: cancellationDetails.vehicleDepreciationFee,
            total_charges: cancellationDetails.totalCancellationCharges,
            ph_charges: cancellationDetails.phCharges,

            subscription_id: contractDetails.subscriptionId,
            fleetio_status: cancellationDetails.fleetioStatusName,

            hub: cancellationDetails.hub,
        }

        const data = await SubscriptionService.putCancellationDetails(
            contractDetails.subscriptionId,
            payload,
            driverDetails.country,
            { msalContext, isAuthenticated },
        );

        if (data) {
            updateFormState(UserFormStates.cancellationDetails,
                { fleetioUrl: data.fleetioLink, zohoUrl: data.zohoLink, chargebeeUrl: data.chargebeeURL })
            navigate(`/cancellation/${driverDetails.country}/${contractDetails.subscriptionId}/confirmation`);
        }
    };

    return (<>
        <NavBar pageTitle={stepNames[currentStepIndex]} backAction={!isFirstStep ? back : undefined} />
        <div style={{ margin: '2.5vh 2.5vw' }}>
            <form onSubmit={onSubmitForm}>
                <div style={{ top: '.5rem', right: '.5rem' }}>
                    <p><strong>Step {currentStepIndex + 1}</strong></p>
                    {step}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2.5vh 0' }}>
                    <SubmitBtn text={isLastStep ? "Finish" : "Next"} />
                </div>
            </form>
        </div>
        {loading && <ForegroundLoadingSpinner />}
    </>);
};


const WarningModal = ({ onClickClose }: { onClickClose: React.MouseEventHandler }) => (
    <Warning
        message="Something went wrong"
        additionalInfo={`We’re sorry, we were unable to process your request.  
        We will investigate the issue and in the meantime, please onboard the customer via an alternative method.`}
        onClickClose={onClickClose}
    />
);


const ForegroundLoadingSpinner = () => (
    <ModalContainer stylesCfg={{ windowTransparency: true }} >
        <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
            <CircularSpinner />
        </div>
    </ModalContainer>
);


const InvalidEmailWarningModal = ({ onClickClose }: { onClickClose: React.MouseEventHandler }) => (
    <Warning
        message="Invalid Email Address Detected"
        additionalInfo={`Please enter a valid email address on Zoho and try again.`}
        onClickClose={onClickClose}
    />
);
